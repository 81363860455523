import React from 'react';
import { Box } from '../../components/ui/Box/Box';
import { useTranslation } from '../../hooks/useTranslation';
import { TermsPageLocales } from '../../locales/types';
import style from './TermsPage.module.scss';

export const TermsPage = () => {
  const { t } = useTranslation<TermsPageLocales>('termsPageLocales');

  return (
    <Box className={style.container}>
      <h2>{t('header')}</h2>
      <p>{t('part1')}</p>
      <span>{t('part2')}</span>
      <ul>
        <li>{t('part2a')}</li>
        <li>{t('part2b')}</li>
      </ul>
      <p>{t('part3')}</p>
      <p>{t('part4')}</p>

      <h3 className={style.header}>{t('header1')}</h3>
      <ol>
        <li>{t('header1part1')}</li>
        <li>{t('header1part2')}</li>
        <li>{t('header1part3')}</li>
        <li>{t('header1part4')}</li>
      </ol>

      <h3 className={style.header}>{t('header2')}</h3>
      <ol>
        <li>
          <span>{t('header2part1')}</span>
          <ul>
            <li>{t('header2part1a')}</li>
            <li>{t('header2part1b')}</li>
            <li>{t('header2part1c')}</li>
          </ul>
        </li>
        <li>{t('header2part2')}</li>
        <li>{t('header2part3')}</li>
        <li>{t('header2part4')}</li>
      </ol>

      <h3 className={style.header}>{t('header3')}</h3>
      <ol>
        <li>{t('header3part1')}</li>
      </ol>

      <h3 className={style.header}>{t('header4')}</h3>
      <ol>
        <li>{t('header4part1')}</li>
        <li>{t('header4part2')}</li>
        <li>{t('header4part3')}</li>
      </ol>

      <h3 className={style.header}>{t('header5')}</h3>
      <ol>
        <li>{t('header5part1')}</li>
        <li>{t('header5part2')}</li>
      </ol>

      <h3 className={style.header}>{t('header6')}</h3>
      <ol>
        <li>{t('header6part1')}</li>
      </ol>

      <h3 className={style.header}>{t('header7')}</h3>
      <ol>
        <li>{t('header7part1')}</li>
      </ol>

      <h3 className={style.header}>{t('header8')}</h3>
      <ol>
        <li>{t('header8part1')}</li>
        <li>{t('header8part2')}</li>
        <li>{t('header8part3')}</li>
      </ol>
    </Box>
  );
};
