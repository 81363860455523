import { joiResolver } from '@hookform/resolvers';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../../../components/ui/Button/Button';
import { Input } from '../../../../../components/ui/Input/Input';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { MainLocales } from '../../../../../locales/types';
import dialogsStyle from '../Dialogs.module.scss';
import { PasswordInputSchema, passwordInputSchema } from '../validation';
import style from './LoginPassword.module.scss';

interface Props {
  email: string;
  handleForgotPasswordButton: () => void;
  handleNotYouButton: () => void;
  onDataSubmit: (data: { password: string }) => Promise<void>;
}

export const LoginPassword: FC<Props> = ({ email, onDataSubmit, handleForgotPasswordButton, handleNotYouButton }) => {
  const { t } = useTranslation<MainLocales>('mainLocales');
  const { control, handleSubmit, errors, formState } = useForm<PasswordInputSchema>({
    resolver: joiResolver(passwordInputSchema),
  });

  return (
    <div className={dialogsStyle.content}>
      <div className={dialogsStyle.left}>
        <form onSubmit={handleSubmit(onDataSubmit)}>
          <h1>{t('inputPasswordHeader')}</h1>
          <p>{email}</p>

          <div className={style.fullWidthFormContainer}>
            <Input
              name='password'
              control={control}
              defaultValue=''
              label={t('passwordInputLabel')}
              error={errors.password ? errors.password : null}
              type='password'
              autoFocus
            />
          </div>
          <div className={style.loginButtonsContainer}>
            <Button
              type='primary'
              htmlType='submit'
              disabled={!formState.isDirty || formState.isSubmitting}
            >
              {t('next')}
            </Button>
            <Button type='link' onClick={handleNotYouButton}>{t('loginPasswordNotYou')}</Button>
          </div>
          <div>
            <span>{t('loginPasswordForgotPassword')}</span>
            <Button type='link' onClick={handleForgotPasswordButton}>{t('loginPasswordResetPassword')}</Button>
          </div>
        </form>
      </div>
    </div>
  );
};
