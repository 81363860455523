import Joi from '@hapi/joi';
import { PatientConfirmBookingDto } from '../../types/api-types';
import { DocumentType } from '../../types/enums/document-type.enum';
import { Gender } from '../../types/enums/gender.enum';
import { emailSchema, phoneNumberSchema } from '../../utils/validation';
import { CalendlyRedirectParams } from './interfaces/calendly-redirect-params.interface';
import { TiranSpecificInputSchema } from './interfaces/tiran-specific-input-schema.interface';

export type RedirectParams = CalendlyRedirectParams;

export const bookingCalendlyRedirectParamsSchema = Joi.object<RedirectParams>({
  answer_1: Joi.string().optional(),
  assigned_to: Joi.string().required(),
  event_end_time: Joi.string().required().isoDate(),
  event_start_time: Joi.string().required().isoDate(),
  event_type_name: Joi.string().required(),
  event_type_uuid: Joi.string().required(),
  invitee_email: emailSchema,
  invitee_first_name: Joi.string().required(),
  invitee_last_name: Joi.string().required(),
  invitee_uuid: Joi.string().required(),
});

export interface BookingCalendlyRedirectInputsSchema extends PatientConfirmBookingDto, TiranSpecificInputSchema {
  [key: string]: any;
  bloodDraw: boolean;
  covidABTest: boolean;
  covidIgMTest: boolean;
  covidPCRTest: boolean;
  email: string;
  englishResults: boolean;
  invoice: boolean;
  O_CAV: boolean;
  O_CDT: boolean;
  O_CDTPRO: boolean;
  O_CUV: boolean;
  O_DAO: boolean;
  O_DROG: boolean;
  O_FEV: boolean;
  O_FOL: boolean;
  O_FSMEIGG: boolean;
  O_HAVAK: boolean;
  O_HBSAK: boolean;
  O_HEPC: boolean;
  O_HIV: boolean;
  O_HOLOTC: boolean;
  O_KV: boolean;
  O_MASERN: boolean;
  O_MGV: boolean;
  O_MINK: boolean;
  O_MINM: boolean;
  O_MINVB: boolean;
  O_MUMPS: boolean;
  O_NAV: boolean;
  O_OMEGA: boolean;
  O_ROET: boolean;
  O_SEV: boolean;
  O_UBI: boolean;
  O_VB12: boolean;
  O_VB6: boolean;
  O_VD2: boolean;
  O_VITA: boolean;
  O_VITB1: boolean;
  O_VITB2: boolean;
  O_VITB3: boolean;
  O_VITC: boolean;
  O_VITD: boolean;
  O_VITE: boolean;
  O_VITK1: boolean;
  O_VITK2: boolean;
  O_VZV: boolean;
  O_ZNV: boolean;
  phoneNumber: string;
  sameDayService: boolean;
}

const optionalString = Joi.string().allow('', null).optional();
const requiredString = Joi.string().required();

export const bookingCalendlyRedirectInputsSchema = Joi.object<BookingCalendlyRedirectInputsSchema>({
  streetName: optionalString,
  houseNumber: optionalString,
  city: optionalString,
  country: optionalString,
  birthDate: Joi.required(),
  nationality: optionalString,
  documents: {
    [DocumentType.NRICFIN]: optionalString,
    [DocumentType.PASSPORT]: optionalString,
  },
  email: requiredString.email({ tlds: false }),
  firstName: requiredString,
  lastName: requiredString,
  gender: Joi.allow(Gender).required(),
  phoneNumber: phoneNumberSchema,
  postalCode: optionalString,
  symptomFree: Joi.boolean().optional(),
  covidABTest: Joi.boolean().required(),
  covidIgMTest: Joi.boolean().optional(), // removed test from selection
  covidPCRTest: Joi.boolean().optional(), // removed test from selection
  sameDayService: Joi.boolean().optional(),
  englishResults: Joi.boolean().optional(),
  bloodDraw: Joi.boolean().optional(),
  O_DROG: Joi.boolean().required(),
  O_CDT: Joi.boolean().required(),
  O_CDTPRO: Joi.boolean().required(),
  O_HIV: Joi.boolean().required(),
  O_HEPC: Joi.boolean().required(),
  O_DAO: Joi.boolean().required(),
  O_OMEGA: Joi.boolean().required(),
  O_FSMEIGG: Joi.boolean().required(),
  O_HAVAK: Joi.boolean().required(),
  O_HBSAK: Joi.boolean().required(),
  O_MASERN: Joi.boolean().required(),
  O_MUMPS: Joi.boolean().required(),
  O_ROET: Joi.boolean().required(),
  O_VZV: Joi.boolean().required(),
  O_VITA: Joi.boolean().required(),
  O_VITB1: Joi.boolean().required(),
  O_VITB2: Joi.boolean().required(),
  O_VITB3: Joi.boolean().required(),
  O_VB6: Joi.boolean().required(),
  O_FOL: Joi.boolean().required(),
  O_HOLOTC: Joi.boolean().required(),
  O_VB12: Joi.boolean().required(),
  O_VITC: Joi.boolean().required(),
  O_VITD: Joi.boolean().required(),
  O_VD2: Joi.boolean().required(),
  O_VITE: Joi.boolean().required(),
  O_VITK1: Joi.boolean().required(),
  O_VITK2: Joi.boolean().required(),
  O_UBI: Joi.boolean().required(),
  O_NAV: Joi.boolean().required(),
  O_KV: Joi.boolean().required(),
  O_CAV: Joi.boolean().required(),
  O_MGV: Joi.boolean().required(),
  O_FEV: Joi.boolean().required(),
  O_SEV: Joi.boolean().required(),
  O_ZNV: Joi.boolean().required(),
  O_CUV: Joi.boolean().required(),
  O_MINK: Joi.boolean().required(),
  O_MINM: Joi.boolean().required(),
  O_MINVB: Joi.boolean().required(),
  invoice: Joi.boolean().optional(),
  invoiceAddress: {
    addressLine1: requiredString,
    addressLine2: requiredString,
    addressLine3: optionalString,
    addressLine4: optionalString,
    addressLine5: optionalString,
  },
});
