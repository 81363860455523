import { captureException } from '@sentry/react';
import { Auth } from 'aws-amplify';
import { CurrentUser } from '../store/store-types';

const { REACT_APP_INSTANCE_NAME } = process.env;

export const captureError = async (error: Error, variables?: any) => {
  const user: CurrentUser = await Auth.currentAuthenticatedUser().catch(() => null);

  captureException(error, scope => {
    scope.setTags({ 'instance': REACT_APP_INSTANCE_NAME });
    variables && scope.setExtras(variables);
    user && scope.setUser(user.attributes);

    return scope;
  });
};
