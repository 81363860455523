import { useContext } from 'react';
import { useQuery } from 'react-query';
import { store } from '../store/store';
import { PatientBooking } from '../types/api-types';
import { ApiPath } from '../types/enums/api-path.enum';
import { ApiQueries } from '../types/enums/api-queries.enum';
import { HttpStatus } from '../types/enums/http-status.enum';
import { getUserToken } from '../utils/auth';
import { Fetch, FetchErrorResponse } from '../utils/fetch';

export const usePatient = () => {
  const { state } = useContext(store);
  const { data, isLoading } = useQuery<PatientBooking | null, ApiQueries, FetchErrorResponse>(ApiQueries.PATIENT, async () => {
    const response = await Fetch.get<PatientBooking>(ApiPath.PATIENT_ME, {
      headers: { Authorization: await getUserToken(state) },
      validateStatus: (status: number) => status < HttpStatus.BAD_REQUEST || status === HttpStatus.NOT_FOUND,
    });

    return response.status === HttpStatus.NOT_FOUND ? null : response.data;
  });

  return {
    patient: data,
    isLoadingPatient: isLoading,
  };
};
