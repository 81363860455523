import React from 'react';
import { Box } from '../../components/ui/Box/Box';
import {
  COMPANY_COMMERCIAL_COURT,
  COMPANY_HEADQUARTERS,
  COMPANY_MANAGER,
  COMPANY_NAME,
  COMPANY_PROFESSIONAL_LAW,
  COMPANY_PROFESSIONAL_LAW_URL,
  COMPANY_REGISTRATION_NUMBER,
  COMPANY_UID_NUMBER,
  CONTACT_EMAIL,
  ONLINE_DISPUTE_RESOLUTION_URL,
} from '../../constants/company';
import { useTranslation } from '../../hooks/useTranslation';
import { CompanyPageLocales } from '../../locales/types';
import style from './CompanyPage.module.scss';

export const CompanyPage = () => {
  const { t } = useTranslation<CompanyPageLocales>('companyPageLocales');

  return (
    <Box className={style.container}>
      <h2>{COMPANY_NAME}</h2>
      <div className={style.section}>
        <p className={style.header}>{t('uidNumber')}: </p>
        <p>{COMPANY_UID_NUMBER}</p>
      </div>

      <div className={style.section}>
        <p className={style.header}>{t('registrationNumber')}: </p>
        <p>{COMPANY_REGISTRATION_NUMBER}</p>
      </div>

      <div className={style.section}>
        <p className={style.header}>{t('commercialCourt')}: </p>
        <span>{t('regionalCourtFor')} </span>
        <span>{COMPANY_COMMERCIAL_COURT}</span>
      </div>

      <div className={style.section}>
        <p className={style.header}>{t('professionalLaw')}: </p>
        <p>{t('professionalLawPart1')}</p>
        <p>
          <span>{t('tradeRegulations')}: </span>
          <a href={COMPANY_PROFESSIONAL_LAW_URL} target='_blank' rel='noopener noreferrer'>
            {COMPANY_PROFESSIONAL_LAW_URL}
          </a>
        </p>
        <p>
          <span>{t('authority')}: </span>
          <span>{COMPANY_PROFESSIONAL_LAW}</span>
        </p>
        <p>{t('chamberOfCommerce')}</p>
      </div>

      <div className={style.section}>
        <span className={style.header}>{t('headquarters')}: </span>
        {COMPANY_HEADQUARTERS.split('\n').map(text => (
          <span key={text} style={{ display: 'block' }}>
            {text}
          </span>
        ))}
      </div>

      <div className={style.section}>
        <p className={style.header}>{t('email')}: </p>
        <span>
          <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
        </span>
      </div>

      <div className={style.section}>
        <p className={style.header}>{t('manager')}: </p>
        <p>{COMPANY_MANAGER}</p>
      </div>

      <div className={style.section}>
        <p>{t('complainsInfo1')}: </p>
        <a href={ONLINE_DISPUTE_RESOLUTION_URL} target='_blank' rel='noopener noreferrer'>
          {ONLINE_DISPUTE_RESOLUTION_URL}
        </a>
      </div>

      <p>{t('complainsInfo2')}</p>
    </Box>
  );
};
