import i18n from 'i18next';
import React, { FC, ReactNode, useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { setAppLanguage } from '../../bootstrap/i18n';
import { useTranslation } from '../../hooks/useTranslation';
import { LayoutLocales } from '../../locales/types';
import { store } from '../../store/store';
import { HomePageDialogState } from '../../types/enums/home-page-dialog-state.enum';
import { Language } from '../../types/enums/language.enum';
import { Path } from '../../types/enums/path.enum';
import { showHomePageDialog } from '../../utils/home-page-dialog-state-handlers';
import { Button } from '../ui/Button/Button';
import style from './Layout.module.scss';
import { NavMenu } from './NavMenu/NavMenu';

interface Props {
  children: ReactNode;
}

export const Layout: FC<Props> = ({ children }) => {
  const { t } = useTranslation<LayoutLocales>('layoutLocales');
  const { dispatch, state } = useContext(store);
  const { push } = useHistory();
  const langSwitcher = () => {
      switch(i18n.language) {
        case 'de':  setAppLanguage(Language.EN).then().catch(); state.language = Language.EN;
          break;
        default:  setAppLanguage(Language.DE).then().catch(); state.language = Language.DE;
          break;
      }
  };


  const NavLinks = () => (
    <>
      <Link to={Path.COMPANY}>{t('companyInfo')}</Link>
      <Link to={Path.TERMS}>{t('terms')}</Link>
      <Link to={Path.PRIVACY_POLICY}>{t('privacyPolicy')}</Link>
      <Link to={useLocation()} onClick={langSwitcher}> {t('language')} </Link>
    </>
  );

  const handleLoginButton = () => {
    push(Path.HOME);
    showHomePageDialog(dispatch, HomePageDialogState.loginEmail);
  };

  return (
    <div className={style.container}>
      <div className={style.content}>
        <div className={style.nav}>
          <Link to={Path.HOME}>
            <img alt='Logo' src='/assets/logo-white.svg' />
            <span className={style.homeLink}>Cerdio × Tiran</span>
          </Link>
          <div className={style.rightSide}>
            <NavLinks />
            {state.currentUser ? (
              <NavMenu />
            ) : (
              <Button secondary type='primary' className={style.navLogInButton} onClick={handleLoginButton}>
                {t('navLogInButton')}
              </Button>
            )}
          </div>
        </div>
        {children}
      </div>
      <div className={style.bottomNavMobile}>
        <NavLinks />
      </div>
      <div className={style.backgroundContainer}>
        <div className={style.backgroundColor} />
        <div className={style.backgroundGreyLine} />
      </div>
    </div>
  );
};
