import { useContext } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { showErrorNotification } from '../../../../../../components/ui/Notifications/Notifications';
import { store } from '../../../../../../store/store';
import { ApiPath } from '../../../../../../types/enums/api-path.enum';
import { Path } from '../../../../../../types/enums/path.enum';
import { getUserToken, refreshCurrentUser } from '../../../../../../utils/auth';
import { Fetch, FetchErrorResponse } from '../../../../../../utils/fetch';
import { hideHomePageDialog } from '../../../../../../utils/home-page-dialog-state-handlers';
import { captureError } from '../../../../../../utils/sentry';

interface Args {
  otp: string;
}

export const useConfirmOtp = () => {
  const { state, dispatch } = useContext(store);
  const { push } = useHistory();
  const [mutation, mutationOptions] = useMutation<void, Args, FetchErrorResponse>(
    async (body: Args) => {
      await Fetch.post(ApiPath.CONFIRM_PHONE_NUMBER, body, {
        headers: { Authorization: await getUserToken(state) },
      });
    },
    {
      onSuccess: async () => {
        // IMPORTANT have to fetch cognito user on successful registration to have user attributes up to date (email/phone verified)
        try {
          await refreshCurrentUser(dispatch);
          hideHomePageDialog(dispatch);
          push(Path.PRODUCT_SELECTION);
        } catch (error) {
          await captureError(error);
        }

        // IMPORTANT reset to avoid showing notifications on parent component rerender
        mutationOptions.reset();
      },
      onError: error => {
        showErrorNotification(error.response?.data!, {
          defaultNotification: 'otpConfirmationFailed',
        });
      },
    },
  );

  return mutation;
};
