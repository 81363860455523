import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {Loader} from '../../components/ui/Loader/Loader';
import {useCosts} from '../../hooks/useCosts';
import { store } from '../../store/store';
import { Path } from '../../types/enums/path.enum';
import { hideHomePageDialog } from '../../utils/home-page-dialog-state-handlers';
import { HomePage } from './HomePage';
import { HomePageDialog } from './HomePageDialog/HomePageDialog';
import { useShouldVerifyAccount } from './hooks/useShouldVerifyAccount';

export const HomePageContainer = () => {
  const { push } = useHistory();
  const { dispatch, state } = useContext(store);
  const { costs, isLoadingCosts } = useCosts();

  // ensures user has mail and phone number verified if registration is stopped at intermediate step
  useShouldVerifyAccount();

  const handleBookingClick = () => {
    push(Path.PRODUCT_SELECTION);
  };

  const handleHomeDialogClose = () => hideHomePageDialog(dispatch);

  return (
  isLoadingCosts ? <Loader fullscreen size='large' /> :
    <>
      <HomePage handleBookingClick={handleBookingClick} costs={costs!}/>
      <HomePageDialog handleDialogClose={handleHomeDialogClose} showDialog={state.showHomePageDialog}/>
    </>
  );
};
