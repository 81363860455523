import React, { createContext, Reducer, useEffect, useReducer } from 'react';
import { HomePageDialogState } from '../types/enums/home-page-dialog-state.enum';
import { Language } from '../types/enums/language.enum';
import { PaymentStatus } from '../types/enums/payment-status.enum';
import { UserVerificationState } from '../types/enums/user-verification-state.enum';
import { getUserVerificationState, refreshCurrentUser } from '../utils/auth';
import { AppContext, CurrentUser, StoreAction, StoreActionType, StoreState } from './store-types';

const { REACT_APP_LANGUAGE } = process.env;
export const store = createContext<AppContext>({} as AppContext);
const { Provider } = store;

export const StateProvider = (props: any) => {
  const initialState: StoreState = {
    bookingLeavingGuardEnabled: true,
    currentUserReady: false,
    language: REACT_APP_LANGUAGE || Language.EN,
    paymentStatus: null,
    resetPasswordData: null,
    homePageDialogState: null,
    showHomePageDialog: false,
  };

  const [state, dispatch] = useReducer<Reducer<StoreState, StoreAction>>((prevState, action) => {
    switch (action.type) {
      case StoreActionType.SET_LANGUAGE: {
        const language = action.payload as Language;

        return { ...prevState, language };
      }
      case StoreActionType.SET_CURRENT_USER: {
        const currentUser = action.payload as CurrentUser;

        return { ...prevState, currentUser, currentUserReady: true };
      }
      case StoreActionType.SET_PAYMENT_STATUS: {
        const paymentStatus = action.payload as PaymentStatus;

        return { ...prevState, paymentStatus };
      }
      case StoreActionType.SET_HOME_PAGE_DIALOG_STATE: {
        const homePageDialogState = action.payload as HomePageDialogState;

        return { ...prevState, homePageDialogState };
      }
      case StoreActionType.SET_SHOW_HOME_PAGE_DIALOG: {
        return { ...prevState, showHomePageDialog: action.payload };
      }
      case StoreActionType.SET_RESET_PASSWORD_DATA: {
        return { ...prevState, resetPasswordData: action.payload };
      }
      case StoreActionType.SET_CONFIRMED_EMAIL: {
        return { ...prevState, confirmedEmail: action.payload as string };
      }
      case StoreActionType.SET_BOOKING_LEAVING_GUARD_ENABLED: {
        return { ...prevState, bookingLeavingGuardEnabled: action.payload as boolean };
      }
      default:
        throw new Error();
    }
  }, initialState);

  useEffect(() => {
    // fetch the info of the user that may be already logged in
    refreshCurrentUser(dispatch, false)
      .then(currentUser => {
        if (getUserVerificationState(currentUser) !== UserVerificationState.NOT_EXISTS) {
          dispatch({ type: StoreActionType.SET_CURRENT_USER, payload: currentUser });
        }
      })
      .catch(() => dispatch({ type: StoreActionType.SET_CURRENT_USER, payload: null }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Provider value={{ state, dispatch }}>{props.children}</Provider>;
};
