import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { showErrorNotification, showSuccessNotification } from '../../../../../components/ui/Notifications/Notifications';
import { store } from '../../../../../store/store';
import { BusinessErrorCode } from '../../../../../types/enums/business-error-code.enum';
import { CognitoErrorCode } from '../../../../../types/enums/cognito-error-code.enum';
import { HomePageDialogState } from '../../../../../types/enums/home-page-dialog-state.enum';
import { Path } from '../../../../../types/enums/path.enum';
import { UserVerificationState } from '../../../../../types/enums/user-verification-state.enum';
import { getUserVerificationState, signIn } from '../../../../../utils/auth';
import { hideHomePageDialog, showHomePageDialog } from '../../../../../utils/home-page-dialog-state-handlers';
import { LoginPassword } from './LoginPassword';

export interface LoginPasswordContainerProps {
  email: string;
  isBookingFlow: boolean;
}

export const LoginPasswordContainer: FC<LoginPasswordContainerProps> = ({ email, isBookingFlow }) => {
  const { dispatch } = useContext(store);
  const { push } = useHistory();

  const handleNotYouButton = () => {
    const homePageDialog: HomePageDialogState = isBookingFlow ? HomePageDialogState.loginEmailBookingFlow : HomePageDialogState.loginEmail;
    showHomePageDialog(dispatch, homePageDialog);
  };

  const handleForgotPasswordButton = () => showHomePageDialog(dispatch, HomePageDialogState.forgotPassword);

  const handleLogin = async ({ password }: { password: string }) => {
    try {
      const currentUser = await signIn(dispatch, { email, password });
      const userVerificationState = getUserVerificationState(currentUser);
      if (userVerificationState === UserVerificationState.VERIFIED) {
        hideHomePageDialog(dispatch);
        showSuccessNotification('loginSuccessful');
      }

      isBookingFlow && push(Path.BOOKING);
    } catch (error) {
      if (error.code === CognitoErrorCode.NotAuthorizedException) {
        error.errorCode = BusinessErrorCode.NO_ACCESS_ERROR;
      }

      showErrorNotification(error, {
        config: { [BusinessErrorCode.NO_ACCESS_ERROR]: 'invalidCredentials' },
        defaultNotification: 'loginFailed',
      });
    }
  };

  return (
    <LoginPassword
      onDataSubmit={handleLogin}
      handleNotYouButton={handleNotYouButton}
      handleForgotPasswordButton={handleForgotPasswordButton}
      email={email}
    />
  );
};
