import { I18n } from 'aws-amplify';
import i18n from 'i18next';
import moment from 'moment';
import 'moment/locale/de';
import { initReactI18next } from 'react-i18next';
import de from '../locales/de.json';
import en from '../locales/en.json';
import { Environment } from '../types/enums/environment.enum';
import { Language } from '../types/enums/language.enum';

const { REACT_APP_ENV } = process.env;

// tslint:disable-next-line:no-async-without-await, no-floating-promises
(async () =>
  i18n.use(initReactI18next).init({
    resources: { en, de },
    fallbackLng: Language.EN,
    debug: REACT_APP_ENV === Environment.Local,
  }))();

export const setAppLanguage = async (language: Language) => {
  I18n.setLanguage(language);
  moment.locale(language);
  window.localStorage.setItem('selectedLanguage', JSON.stringify(language));
  await i18n.changeLanguage(language);
};
