import Joi from '@hapi/joi';
import { AxiosError } from 'axios';
import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Loader } from '../../components/ui/Loader/Loader';
import { PAYMENT_CHECK_INTERVAL, PAYMENT_CHECK_TIMEOUT } from '../../constants/common';
import { useTranslation } from '../../hooks/useTranslation';
import { MainLocales } from '../../locales/types';
import { store } from '../../store/store';
import { StoreActionType } from '../../store/store-types';
import { Booking } from '../../types/api-types';
import { ApiPath } from '../../types/enums/api-path.enum';
import { HomePageDialogState } from '../../types/enums/home-page-dialog-state.enum';
import { Path } from '../../types/enums/path.enum';
import { PaymentStatus } from '../../types/enums/payment-status.enum';
import { getUserToken } from '../../utils/auth';
import { Fetch } from '../../utils/fetch';
import { showHomePageDialog } from '../../utils/home-page-dialog-state-handlers';
import { parseQueryParams } from '../../utils/parsers';
import { captureError } from '../../utils/sentry';

const paymentPageParamsSchema = Joi.object<{ bookingId: number; success: boolean }>({
  bookingId: Joi.number().required(),
  success: Joi.boolean().required(),
});

export const PaymentPage = () => {
  const { t } = useTranslation<MainLocales>('mainLocales');
  const location = useLocation();
  const { value: queryParams, error: queryParamsValidationError } = parseQueryParams(location, paymentPageParamsSchema);
  const { push } = useHistory();
  const { state, dispatch } = useContext(store);
  let paymentCheckTries = 0;

  useEffect(() => {
  const handleFetchBookingResponse = ({ data: booking }: { data: Booking }) => {
    switch (booking.status) {
      case 'CANCELLED': {
        return push(Path.HOME);
      }
      case 'WAITING_FOR_PAYMENT': {
        if (paymentCheckTries * PAYMENT_CHECK_INTERVAL > PAYMENT_CHECK_TIMEOUT) {
          dispatch({ type: StoreActionType.SET_PAYMENT_STATUS, payload: PaymentStatus.FAILURE });
          showHomePageDialog(dispatch, HomePageDialogState.paymentFailure);

          return push(Path.HOME);
        }

        paymentCheckTries++;
        break;
      }
      case 'PAYMENT_SUCCEEDED': {
        dispatch({ type: StoreActionType.SET_PAYMENT_STATUS, payload: PaymentStatus.SUCCESS });
        showHomePageDialog(dispatch, HomePageDialogState.paymentSuccess);

        return push(Path.HOME);
      }
      case 'PAYMENT_FAILURE': {
        dispatch({ type: StoreActionType.SET_PAYMENT_STATUS, payload: PaymentStatus.FAILURE });
        showHomePageDialog(dispatch, HomePageDialogState.paymentFailure);

        return push(Path.HOME);
      }
    }
  };

  const handleFetchBookingError = async (error: AxiosError) => {
    await captureError(error);
    push(Path.HOME);
  };


    let interval: NodeJS.Timeout;
    if (queryParamsValidationError || !state.currentUser) {
      return push(Path.HOME);
    }

    if (queryParams.success) {
      interval = setInterval(async () => {
        Fetch.get<Booking>(`${ApiPath.BOOKINGS}/${queryParams.bookingId}`, { headers: { Authorization: await getUserToken(state) } })
          .then(handleFetchBookingResponse)
          .catch(handleFetchBookingError);
      }, PAYMENT_CHECK_INTERVAL);
    } else {
      return push(Path.HOME);
    }

    return () => clearInterval(interval);
  }, [dispatch, paymentCheckTries, push, state, queryParams, queryParamsValidationError]);

  return <Loader background fullscreen size='large' tip={t('waitingForPayment')} />;
};
