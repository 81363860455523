import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { Loader } from '../../components/ui/Loader/Loader';
import { usePatient } from '../../hooks/usePatient';
import { useWindowHeight } from '../../hooks/useWindowHeight';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import { store } from '../../store/store';
import breakpoints from '../../styles/breakpoints.scss';
import { CalendlyEvent } from '../../types/enums/calendly-event.enum';
import { BookingPage } from './BookingPage';

export const BookingPageContainer = () => {
  const { state } = useContext(store);
  const windowWidth = useWindowWidth();
  const windowHeight = useWindowHeight();
  const [isCalendlyFormDirty, setIsCalendlyFormDirty] = useState(false);
  const { isLoadingPatient, patient } = usePatient();

  useEffect(() => {
    const isCalendlyDateSelectedEvent = (e: any) => e.data.event && e.data.event.indexOf(CalendlyEvent.DateAndTimeSelected) === 0;
    // @ts-ignore
    const isCalendlyEventBooked = (e: any) => e.data.event && e.data.event.indexOf(CalendlyEvent.EventScheduled) === 0;
    function isCalendlyEvent(e: any) {
      return e.data.event &&
        e.data.event.indexOf('calendly') === 0;
    }
    const onMessageListener = (e: any) => {
      if (isCalendlyDateSelectedEvent(e)) {
        setIsCalendlyFormDirty(true);
        // tslint:disable-next-line:no-console
        // console.log(e.data);
      }
      if (isCalendlyEvent(e)) {
        // tslint:disable-next-line:no-console
        // console.log(e.data);
      }
      if (isCalendlyEventBooked(e)) {
        // tslint:disable-next-line:no-console
        // console.log(e.data);
      }
    };

    window.addEventListener('message', onMessageListener);

    return () => {
      window.removeEventListener('message', onMessageListener);
    };
  });

  // IMPORTANT widget height depends on locations number
  const widgetStyle: CSSProperties = (() => {
    switch (true) {
      case windowWidth >= parseInt(breakpoints.laptopS): {
        return { height: '100rem', overflow: 'hidden' };
      }
      case windowWidth >= parseInt(breakpoints.tablet): {
        return { height: '300rem', overflow: 'hidden' };
      }
      default: {
        // 18rem => header + footer
        return { height: `calc(${windowHeight}px - 18rem)`, overflow: 'hidden' };
      }
    }
  })();

  if (isLoadingPatient) {
    return <Loader fullscreen size='large' />;
  }

  return (
    <BookingPage
      widgetStyle={widgetStyle}
      email={state.currentUser?.attributes.email}
      isCalendlyFormDirty={isCalendlyFormDirty}
      name={patient?.patient.name}
    />
  );
};
