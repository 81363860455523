export const RECAPTCHA_TIMEOUT = 60 * 1000; // 1 minute
export const PAYMENT_CHECK_INTERVAL = 1000; // 1 second
export const PAYMENT_CHECK_TIMEOUT = 2 * 60 * 1000; // 2 minutes

export const MAX_PHONE_NUMBER_LENGTH = 12;
export const MAX_INPUT_LENGTH = 256;
export const MAX_POSTAL_CODE_LENGTH = 16;
export const MAX_DOCUMENT_LENGTH = 20;
export const MAX_SVNUMMER_LENGTH = 10;
export const MAX_VEHICLE_REGISTRATION_PLATE_LENGTH = 20;
export const PHONE_VERIFICATION_CODE_LENGTH = 6;

export const SUPPORT_EMAIL = 'support@cerdio.com';
export const APP_NAME = 'Cerdio';
