import { useContext } from 'react';
import { useMutation } from 'react-query';
import { showErrorNotification, showInfoNotification } from '../../../../components/ui/Notifications/Notifications';
import { store } from '../../../../store/store';
import { ApiPath } from '../../../../types/enums/api-path.enum';
import { BusinessErrorCode } from '../../../../types/enums/business-error-code.enum';
import { getUserToken } from '../../../../utils/auth';
import { Fetch, FetchErrorResponse } from '../../../../utils/fetch';

interface Args {
  email: string;
}

export const useChangeEmail = () => {
  const { state } = useContext(store);
  const [mutation, mutationOptions] = useMutation<
    void,
    Args,
    FetchErrorResponse
  >(async (body: Args) => {
    const { data } = await Fetch.put(ApiPath.CHANGE_EMAIL, body, {
      headers: { Authorization: await getUserToken(state) },
      params: { language: state.language },
    });

    return data;
  }, {
    onSuccess: () => {
      showInfoNotification('verificationEmailSent');
      // IMPORTANT reset to avoid showing notifications on parent component rerender
      mutationOptions.reset();
    },
    onError: error => {
      showErrorNotification(error.response?.data!, {
        config: { [BusinessErrorCode.DUPLICATE_ERROR]: 'emailUsed' },
      });
    }
  });

  return mutation;
};
