import { useContext } from 'react';
import { useMutation } from 'react-query';
import { showErrorNotification } from '../../../components/ui/Notifications/Notifications';
import { store } from '../../../store/store';
import { CheckoutSessionResponse, CreatePaymentSessionDto } from '../../../types/api-types';
import { ApiPath } from '../../../types/enums/api-path.enum';
import { getUserToken } from '../../../utils/auth';
import { Fetch, FetchErrorResponse } from '../../../utils/fetch';

interface Args extends CreatePaymentSessionDto {}

export const useCreateStripePaymentCheckoutSession = () => {
  const { state } = useContext(store);
  const [mutation, mutationOptions] = useMutation<CheckoutSessionResponse,
    Args,
    FetchErrorResponse>(async (args: Args) => {
    const { data } = await Fetch.post<CheckoutSessionResponse>(
      ApiPath.PAYMENTS_STRIPE_SESSION,
      args,
      {
        headers: { Authorization: await getUserToken(state) },
        params: { language: state.language },
      },
    );

    return data;
  });

  if (!mutationOptions.isLoading && mutationOptions.error?.response) {
    showErrorNotification(mutationOptions.error.response.data, { defaultNotification: 'bookingConfirmationFailed' });
  }

  if (!mutationOptions.isLoading && mutationOptions.isSuccess) {
    // IMPORTANT reset to avoid showing notifications on parent component rerender
    mutationOptions.reset();
  }

  return mutation;
};
