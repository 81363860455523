import { Location } from 'history';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { BookingLeavingWarningDialog } from './BookingLeavingWarningDialog/BookingLeavingWarningDialog';

interface Props {
  // Use as "message" prop of Prompt of React-Router
  shouldBlockNavigation: (location: Location) => boolean;
  // When should shouldBlockNavigation be invoked, (same as "when" prop of Prompt of React-Router)
  when?: boolean;
}

// Based on https://medium.com/@michaelchan_13570/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
export const BookingLeavingGuard: FC<Props> = ({ when, shouldBlockNavigation }) => {
  const { push } = useHistory();
  const [showDialog, setShowDialog] = useState(false);
  const [fromLocation, setFromLocation] = useState<Location | null>(null);
  const [userConfirmedNavigation, setUserConfirmedNavigation] = useState(false);

  useEffect(() => {
    if (userConfirmedNavigation && fromLocation) {
      // Navigate to the previous blocked location
      push(fromLocation.pathname);
    }
  }, [userConfirmedNavigation, fromLocation, push]);

  const closeModal = useCallback(() => setShowDialog(false), [setShowDialog]);

  const handleBlockedNavigation = useCallback((nextLocation: Location): boolean => {
    if (!userConfirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setShowDialog(true);
      setFromLocation(nextLocation);

      return false;
    }

    return true;
  }, [shouldBlockNavigation, userConfirmedNavigation]);

  const handleConfirmNavigationClick = useCallback(() => {
    setShowDialog(false);
    setUserConfirmedNavigation(true);
    window.sessionStorage.clear();
  }, []);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation}/>
      <BookingLeavingWarningDialog
        showDialog={showDialog}
        handleCancel={closeModal}
        handleConfirm={handleConfirmNavigationClick}
        handleDialogClose={closeModal}
      />
    </>
  );
};
