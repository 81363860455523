import React, { FC } from 'react';
import { useTranslation } from '../../../../hooks/useTranslation';
import { Notifications } from '../../../../locales/types';
import { MessageType } from '../Notifications';
import style from './Toast.module.scss';

interface ToastProps {
  translationKey: keyof Notifications;
  type: keyof MessageType;
}

export const Toast: FC<ToastProps> = ({ translationKey, type }) => {
  const { t } = useTranslation<Notifications>('notifications');

  return <div className={style.toast}><span className={style[type]}>{t(translationKey)}</span></div>;
};
