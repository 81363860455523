import React, { FC, useContext } from 'react';
import { showErrorNotification } from '../../../../../components/ui/Notifications/Notifications';
import { store } from '../../../../../store/store';
import { ApiPath } from '../../../../../types/enums/api-path.enum';
import { HomePageDialogState } from '../../../../../types/enums/home-page-dialog-state.enum';
import { Platform } from '../../../../../types/enums/platform.enum';
import { RecaptchaActionType } from '../../../../../types/enums/recaptcha-action-type.enum';
import { verifyRecaptcha } from '../../../../../utils/auth';
import { Fetch } from '../../../../../utils/fetch';
import { showHomePageDialog } from '../../../../../utils/home-page-dialog-state-handlers';
import { ForgotPassword } from './ForgotPassword';

interface Props {
  email: string;
  setEmail: (email: string) => void;
}

export const ForgotPasswordContainer: FC<Props> = ({ email, setEmail }) => {
  const { dispatch, state } = useContext(store);

  const handleBackIconClick = () => showHomePageDialog(dispatch, HomePageDialogState.loginPassword);

  const sendResetPasswordEmail = async (data: { email: string }) => {
    try {
      await verifyRecaptcha(RecaptchaActionType.RESET_PASSWORD, { email: data.email });
      await Fetch.post(ApiPath.FORGOT_PASSWORD, { email: data.email, platform: Platform.BOOKING_WEB }, {
        params: { language: state.language },
      });
      setEmail(data.email);
      showHomePageDialog(dispatch, HomePageDialogState.resetPasswordEmailSent);
    } catch (error) {
      showErrorNotification(error);
    }
  };

  return <ForgotPassword email={email} onDataSubmit={sendResetPasswordEmail} handleBackIconClick={handleBackIconClick} />;
};
