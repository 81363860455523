import React, { useContext } from 'react';
import { Button } from '../../../../../components/ui/Button/Button';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { MainLocales } from '../../../../../locales/types';
import { store } from '../../../../../store/store';
import fonts from '../../../../../styles/typography.scss';
import { hideHomePageDialog } from '../../../../../utils/home-page-dialog-state-handlers';
import dialogsStyle from '../Dialogs.module.scss';
import style from './BookingSuccess.module.scss';

export const BookingSuccess = () => {
  const { dispatch } = useContext(store);
  const { t } = useTranslation<MainLocales>('mainLocales');

  const handleDoneClick = () => hideHomePageDialog(dispatch);

  return (
    <div className={dialogsStyle.content}>
      <div className={dialogsStyle.left}>
        <h1>{t('endSuccessTitle')}</h1>
        <p className={dialogsStyle.info}>{t('endSuccessEmailInfo')}</p>
        <div>
          <Button type='primary' onClick={handleDoneClick}>
            {t('successDone')}
          </Button>
        </div>
      </div>
      <div className={dialogsStyle.right}>
        <img className={style.logo} alt='logo' src={`/assets/tiran-lab-logo.png`} />
        <h1 className={style.header}>{t('endPreparationTitle')}</h1>
        <p style={{ fontSize: fonts.fontDefault }}>{t('endPreparationInfo1')}</p>
        {/*<p style={{ fontSize: fonts.fontDefault }}>{t('endPreparationInfo2')}</p>*/}
      </div>
    </div>
  );
};
