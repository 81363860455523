import { joiResolver } from '@hookform/resolvers';
import React, { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BookingOptionalExtras } from '../../components/BookingOptionalExtras/BookingOptionalExtras';
import { Box } from '../../components/ui/Box/Box';
import { Cost, Patient } from '../../types/api-types';
import { BookingStep } from '../BookingCalendlyRedirectPage/enums/booking-step.enum';
import { bookingCalendlyRedirectInputsSchema, BookingCalendlyRedirectInputsSchema, RedirectParams } from '../BookingCalendlyRedirectPage/validation';

interface Props {
  costs: Cost;
  email: string;
  patient?: Patient;
  phoneNumber: string;
  preSelection: boolean;
  redirectParams?: RedirectParams;
}

export const BookingProductSelectionPage: FC<Props> = ({ ...props }) => {
  const [step, setBookingStep] = useState(BookingStep.OPTIONAL_EXTRAS);
  const methods = useForm<BookingCalendlyRedirectInputsSchema>({
    resolver: joiResolver(bookingCalendlyRedirectInputsSchema),
    mode: 'onChange',
  });


  return (
    <>
      <Box>
        <FormProvider {...methods}>
          <form>
            <BookingOptionalExtras {...props} setBookingStep={setBookingStep} visible={step === BookingStep.OPTIONAL_EXTRAS} />
          </form>
        </FormProvider>
      </Box>
    </>
  );
};
