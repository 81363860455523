import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BookingForm } from '../../components/BookingForm/BookingForm';
import { Box } from '../../components/ui/Box/Box';
import { Patient } from '../../types/api-types';
import { BookingCalendlyRedirectInputsSchema } from '../BookingCalendlyRedirectPage/validation';

interface Props {
  email: string;
  patient?: Patient;
  phoneNumber: string;
}

export const RequestFormDetailsPage: FC<Props> = ({ patient, phoneNumber, email }) => {
  // IMPORTANT form created to avoid many conditions inside booking form
  const methods = useForm<BookingCalendlyRedirectInputsSchema>();

  return (
    <FormProvider {...methods}>
      <Box>
        <BookingForm patient={patient} phoneNumber={phoneNumber} email={email} readonly visible />
      </Box>
    </FormProvider>
  );
};
