import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../../components/ui/Button/Button';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { MainLocales } from '../../../../../locales/types';
import colors from '../../../../../styles/colors.scss';
import { Path } from '../../../../../types/enums/path.enum';
import dialogsStyle from '../Dialogs.module.scss';

export const BookingFailure = () => {
  const { t } = useTranslation<MainLocales>('mainLocales');
  const { push } = useHistory();

  const handleTryAgainClick = () => {
    push(Path.BOOKING);
  };

  return (
    <div className={dialogsStyle.content}>
      <div className={dialogsStyle.left}>
        <h1 style={{ color: colors.errorColor }}>{t('endFailureTitle')}</h1>
        <p className={dialogsStyle.info}>{t('endFailureInfo')}</p>
        <div>
          <Button type='primary' onClick={handleTryAgainClick}>
            {t('tryAgain')}
          </Button>
        </div>
      </div>
    </div>
  );
};
