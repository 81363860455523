import { Language } from '../types/enums/language.enum';

const FIELDS_SEPARATOR = '^';

export const joinAddressFields = (streetName: string | undefined, houseNumber: string | undefined) => streetName + FIELDS_SEPARATOR + houseNumber;

export const joinNameFields = (firstName: string, lastName: string) => firstName + FIELDS_SEPARATOR + lastName;

export const parseNameFields = (dbAddress: string = FIELDS_SEPARATOR) => {
  const [firstName, lastName] = dbAddress.split(FIELDS_SEPARATOR);

  return [firstName, lastName];
};

export const parseAddressFields = (dbName: string = FIELDS_SEPARATOR) => {
  const [streetName, houseNumber] = dbName.split(FIELDS_SEPARATOR);

  return [streetName, houseNumber];
};

export const toCurrency = (valueInCents: number = 0, lang: Language) =>
  (valueInCents / 100).toLocaleString(lang, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
