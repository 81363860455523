import { joiResolver } from '@hookform/resolvers';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../../../components/ui/Button/Button';
import { PhoneNumberInput } from '../../../../../components/ui/PhoneNumberInput/PhoneNumberInput';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { MainLocales } from '../../../../../locales/types';
import dialogsStyle from '../Dialogs.module.scss';
import style from './PhoneConfirmation.module.scss';
import { phoneConfirmationInputsSchema, PhoneConfirmationInputsSchemaInterface } from './validation';

interface Props {
  defaultPhoneNumber: string;
  onPhoneNumberSubmit: (data: { phoneNumber: string }) => void;
}

export const PhoneConfirmation: FC<Props> = ({ defaultPhoneNumber, onPhoneNumberSubmit }) => {
  const { t } = useTranslation<MainLocales>('mainLocales');
  const { control, handleSubmit, formState } = useForm<PhoneConfirmationInputsSchemaInterface>({
    resolver: joiResolver(phoneConfirmationInputsSchema),
  });

  return (
    <div className={dialogsStyle.content}>
      <div className={dialogsStyle.left}>
        <h1>{t('phoneConfirmationHeader')}</h1>
        <p className={style.finishRegistration}>{t('phoneConfirmationFinishRegistration')}</p>
        <form onSubmit={handleSubmit(onPhoneNumberSubmit)}>
          <PhoneNumberInput
            name='phoneNumber'
            defaultValue={defaultPhoneNumber}
            error={null}
            className={style.spaceBottomContainer}
            control={control}
          />
          <Button
            type='primary'
            htmlType='submit'
            disabled={formState.isSubmitting}
            className={style.spaceBottomContainer}
          >
            {t('next')}
          </Button>
        </form>
      </div>
      <div className={dialogsStyle.right}>
        <h1>{t('phoneConfirmationWhyWeNeedPhone')}</h1>
        <p>{t('phoneConfirmationWhyWeNeedPhoneExplanation')}</p>
      </div>
    </div>
  );
};
