import cx from 'classnames';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import moment from 'moment';
import React, { FC, ReactNode, useContext } from 'react';
import { Box } from '../../components/ui/Box/Box';
import { Table } from '../../components/ui/Table/Table';
import { useTranslation } from '../../hooks/useTranslation';
import { MainLocales } from '../../locales/types';
import { store } from '../../store/store';
import { Patient, PatientResult } from '../../types/api-types';
import { ApiPath } from '../../types/enums/api-path.enum';
import { SendResultsTo } from '../../types/enums/send-results-to.enum';
import { getUserToken } from '../../utils/auth';
import style from './ResultsPage.module.scss';

const { REACT_APP_API_URL: apiUrl } = process.env;
const phoneUtil = PhoneNumberUtil.getInstance();

interface Props {
  patient?: Patient;
  results?: PatientResult[];
}

export const ResultsPage: FC<Props> = ({ patient, results }) => {
  const { state } = useContext(store);
  const { t } = useTranslation<MainLocales>('mainLocales');

  const dataSource: { date: string; fileLink: ReactNode; sentTo?: string; }[] = results?.map(({ id, createdAt, sendResultsTo }) => {
    const phoneNumber = phoneUtil.parseAndKeepRawInput(patient!.phoneNumber);
    const onLinkClick = async () => {
      const token = await getUserToken(state, false);
      window.open(`${apiUrl}/${ApiPath.PATIENT_RESULTS}/${id}?token=${token}`);
    };
    const fileLink = <span role='button' className={style.fileLinkButton} onClick={onLinkClick}>{`result-${id}.pdf`}</span>;
    const sentTo = (() => {
      switch (sendResultsTo) {
        case SendResultsTo.EMAIL: return patient?.email;
        case SendResultsTo.MOBILE: return phoneUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL);
      }
    })();

    return {
      key: id,
      date: moment(createdAt).format('DD.MM.YYYY'),
      fileLink,
      sentTo,
    };
  }) || [];

  return (
    <Box>
      <h1>{t('resultsHeader', { name: patient?.name })}</h1>
      <div className={style.row}>
        <p className={style.column}>
          {
            t('resultsSubheader').split('\n').map(text => <span style={{ display: 'block' }} key={text}>{text}</span>)
          }
        </p>
        <div className={cx(style.column, style.mobileOnly)}/>
      </div>
      <div className={style.row}>
        <p className={style.header}>{t('results')}</p>
      </div>
      <Table className={style.resultsTable} dataSource={dataSource} columns={[
        {
          title: t('date'),
          dataIndex: 'date',
          key: 'date',
          width: '10rem',
        },
        {
          title: t('sentTo'),
          dataIndex: 'sentTo',
          key: 'sentTo',
          className: style.phoneNumberCell,
          ellipsis: true,
        },
        {
          title: t('file'),
          dataIndex: 'fileLink',
          key: 'fileLink',
          ellipsis: true,
          width: '10rem',
        },
      ]}/>
    </Box>
  );
};
