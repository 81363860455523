import { ObjectSchema, ValidationError } from '@hapi/joi';
import { Location } from 'history';
import { parse } from 'qs';

export const parseQueryParams = (
  location: Location,
  validator?: ObjectSchema,
  decode: boolean = false,
): { error?: ValidationError; value: any } => {
  const queryParams = parse(location.search, {
    ignoreQueryPrefix: true,
    // Use raw decoder to avoid replacing special characters e.g. '+' -> ' ' in emails
    decoder: decode ? undefined : (c) => c,
  });

  if (validator) {
    const { error, value } = validator.validate(queryParams, {
      allowUnknown: true,
    });

    return { error, value };
  }

  return { value: queryParams };
};
