import { loadStripe } from '@stripe/stripe-js/pure';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Loader } from '../../components/ui/Loader/Loader';
import { useCosts } from '../../hooks/useCosts';
import { usePatient } from '../../hooks/usePatient';
import { store } from '../../store/store';
import { StoreActionType } from '../../store/store-types';
import { Booking, PatientConfirmBookingDto, TiranCostPayload } from '../../types/api-types';
import { HomePageDialogState } from '../../types/enums/home-page-dialog-state.enum';
import { Path } from '../../types/enums/path.enum';
import { PaymentStatus } from '../../types/enums/payment-status.enum';
import { onBeforeUnloadListener } from '../../utils/events';
import { showHomePageDialog } from '../../utils/home-page-dialog-state-handlers';
import { parseQueryParams } from '../../utils/parsers';
import { captureError } from '../../utils/sentry';
import { joinAddressFields, joinNameFields } from '../../utils/string-manipulation';
import { BookingCalendlyRedirectPage } from './BookingCalendlyRedirectPage';
import { useConfirmBooking } from './hooks/useConfirmBooking';
import { useCreateStripePaymentCheckoutSession } from './hooks/useCreateStripePaymentCheckoutSession';
import { BookingCalendlyRedirectInputsSchema, bookingCalendlyRedirectParamsSchema } from './validation';

const { REACT_APP_STRIPE_API_KEY } = process.env;
const stripePromise = loadStripe(REACT_APP_STRIPE_API_KEY);
// TODO use stripePromise inside onDataSubmit after create-session API call

export const BookingCalendlyRedirectPageContainer = () => {
  const { state, dispatch } = useContext(store);
  const { push, location } = useHistory();
  const { value: parsedRedirectParams, error: redirectParamsValidationError } = parseQueryParams(location, bookingCalendlyRedirectParamsSchema, true);
  const { phone_number: phoneNumber, email } = state.currentUser?.attributes!;
  const redirectParams = useMemo(() => parsedRedirectParams, [parsedRedirectParams]);

  const { isLoadingPatient, patient } = usePatient();
  const { isLoadingCosts, costs } = useCosts();
  const confirmBooking = useConfirmBooking();
  const createStripePaymentCheckoutSession = useCreateStripePaymentCheckoutSession();

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnloadListener);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnloadListener);
    };
  }, []);

  const handleBookingConfirmation = useCallback(
    async (dto: BookingCalendlyRedirectInputsSchema) => {
      const { streetName, houseNumber, firstName, lastName, ...restPatientData } = dto;
      const patientData: PatientConfirmBookingDto = {
        ...restPatientData,
        address: joinAddressFields(streetName, houseNumber),
        name: joinNameFields(firstName, lastName),
      };

      return confirmBooking({ patientData, redirectParams });
    },
    [confirmBooking, redirectParams],
  );

  const handlePayments = useCallback(
    async (dto: BookingCalendlyRedirectInputsSchema, booking: Booking) => {
      const stripe = await stripePromise;
      // @ts-ignore
      const { covidTest, ...restCosts }: TiranCostPayload = costs!.payload;
      const selectedCosts = Object.keys(restCosts).reduce(
        (prev, cost) => (dto[cost as keyof Omit<TiranCostPayload, 'covidTest'>] ? prev.concat(cost as any) : prev),
        [],
      );
      const session = await createStripePaymentCheckoutSession({
        bookingId: booking.id,
        items: [ ...selectedCosts], // covidTest is defunct base cost
      });
/*      const restCosts: object = costs!.payload;
      const selectedCosts = Object.keys(restCosts).reduce(
        (prev, cost) => (dto[cost as keyof Omit<TiranCostPayload, 'covidTest'>] ? prev.concat(cost as any) : prev),
        [],
      );
      const session = await createStripePaymentCheckoutSession({
        bookingId: booking.id,
        items: selectedCosts, // covidTest is base cost
      });*/

      // enable payment redirect
      window.removeEventListener('beforeunload', onBeforeUnloadListener);

      return stripe?.redirectToCheckout({ sessionId: session.id });
    },
    [costs, createStripePaymentCheckoutSession],
  );

  const onDataSubmit = useCallback(
    async (dto: BookingCalendlyRedirectInputsSchema) => {
      const booking = await handleBookingConfirmation(dto);
      const paymentResult = await handlePayments(dto, booking);
      if (paymentResult?.error) {
        // @ts-ignore
        await captureError(paymentResult.error);
        dispatch({ type: StoreActionType.SET_PAYMENT_STATUS, payload: PaymentStatus.FAILURE });
        showHomePageDialog(dispatch, HomePageDialogState.paymentFailure);
        push(Path.HOME);
      }
    },
    [handleBookingConfirmation, handlePayments, dispatch, push],
  );

  if (redirectParamsValidationError) {
    return <Redirect to={Path.HOME} />;
  }

  if (isLoadingPatient || isLoadingCosts) {
    return <Loader fullscreen size='large' />;
  }

  return (
    <BookingCalendlyRedirectPage
      onDataSubmit={onDataSubmit}
      redirectParams={redirectParams}
      patient={patient?.patient}
      phoneNumber={phoneNumber}
      email={email}
      costs={costs!}
    />
  );
};
