import { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { showErrorNotification, showInfoNotification } from '../components/ui/Notifications/Notifications';
import { MAX_RESEND_COUNT, MAX_RESEND_ERROR_MESSAGE } from '../constants/fetch';
import { store } from '../store/store';
import { SuccessResponse } from '../types/api-types';
import { ApiPath } from '../types/enums/api-path.enum';
import { BusinessErrorCode } from '../types/enums/business-error-code.enum';
import { RecaptchaActionType } from '../types/enums/recaptcha-action-type.enum';
import { getUserToken, verifyRecaptcha } from '../utils/auth';
import { Fetch, FetchErrorResponse } from '../utils/fetch';

interface Args {
  phoneNumber: string;
}

export const useSendPhoneConfirmationCode = () => {
  const { state } = useContext(store);
  const [resendCount, setResendCount] = useState(0);
  const [mutation, mutationOptions] = useMutation<
    SuccessResponse,
    Args,
    FetchErrorResponse
  >(async (body: Args) => {
    if (resendCount > MAX_RESEND_COUNT) {
      // TODO should that error notification be displayed ?
      showErrorNotification({ message: MAX_RESEND_ERROR_MESSAGE, errorCode: BusinessErrorCode.MAX_REQUESTS_ERROR }, {
        defaultNotification: 'maxRequests',
      });

      return { success: false };
    }

    setResendCount(resendCount + 1);
    await verifyRecaptcha(RecaptchaActionType.VERIFY_PHONE, body);
    await Fetch.post(ApiPath.SEND_PHONE_CONFIRMATION_CODE, body, {
      headers: { Authorization: await getUserToken(state) },
      params: { language: state.language },
    });

    return { success: true };
  },{
    onError: error => showErrorNotification(error.response?.data!),
    onSuccess: () => {
      showInfoNotification('confirmationCodeSent');
      // IMPORTANT reset to avoid showing notifications on parent component rerender
      mutationOptions.reset();
    }
  });

  return { mutation, isLoading: mutationOptions.isLoading };
};
