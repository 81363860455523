import React, { FC } from 'react';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { Price } from '../../../components/Price/Price';
import '../../../components/ui/Accordion/ReactAccessibleAccordion.css';
import { useTranslation } from '../../../hooks/useTranslation';
import { HomeBanner } from '../../../locales/types';
import { Cost, TiranCostPayload } from '../../../types/api-types';
import styles from './Banner.module.scss';

interface Props {
  costs: Cost;
}

export const Banner: FC<Props> = ({ costs }) => {
  const { t } = useTranslation<HomeBanner>('homeBanner');
  const costsPayload: TiranCostPayload = costs?.payload as TiranCostPayload;

  const items = [
    {
      uuid: 'Category-1',
      rank: 3,
      heading: t('price_1'),
      content: (
        <div>
          {/*
            <li className={styles.pricingLine}>
            <span>{t('price_1_1')}</span>
            <Price symbol={costs.currencySymbol} value={costsPayload.covidPCRTest} />
          </li>
          */}
          <li className={styles.pricingLine}>
            <span>{t('price_1_2')}</span>
            <Price symbol={costs.currencySymbol} value={costsPayload.covidABTest} />
          </li>
        </div>
      ),
    },
    {
      uuid: 'Category-2',
      rank: 1,
      heading: t('price_2'),
      content: (
        <div>
            <li className={styles.pricingLine}>
              <span>{t('price_2_1')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_DROG} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_2_2')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_CDT} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_2_3')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_CDTPRO} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_2_4')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_HIV} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_2_5')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_HEPC} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_2_6')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_DAO} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_2_7')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_OMEGA} />
            </li>
        </div>
      ),
    },
    {
      uuid: 'Category-3',
      rank: 2,
      heading: t('price_3'),
      content: (
        <div>
            <li className={styles.pricingLine}>
              <span>{t('price_3_1')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_FSMEIGG} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_3_2')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_HAVAK} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_3_3')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_HBSAK} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_3_4')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_MASERN} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_3_5')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_MUMPS} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_3_6')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_ROET} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_3_7')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_VZV} />
            </li>
        </div>
      ),
    },
    {
      uuid: 'Category-4',
      rank: 4,
      heading: t('price_4'),
      content: (
        <div>
            <li className={styles.pricingLine}>
              <span>{t('price_4_1')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_VITA} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_4_2')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_VITB1} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_4_3')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_VITB2} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_4_4')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_VITB3} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_4_5')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_VB6} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_4_6')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_FOL} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_4_7')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_HOLOTC} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_4_8')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_VB12} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_4_9')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_VITC} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_4_10')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_VITD} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_4_11')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_VD2} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_4_12')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_VITE} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_4_13')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_VITK1} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_4_14')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_VITK2} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_4_15')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_UBI} />
            </li>
        </div>
      ),
    },
    {
      uuid: 'Category-5',
      rank: 5,
      heading: t('price_5'),
      content: (
        <div>
            <li className={styles.pricingLine}>
              <span>{t('price_5_1')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_NAV} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_5_2')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_KV} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_5_3')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_CAV} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_5_4')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_MGV} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_5_5')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_FEV} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_5_6')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_SEV} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_5_7')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_ZNV} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_5_8')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_CUV} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_5_9')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_MINK} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_5_10')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_MINM} />
            </li>
            <li className={styles.pricingLine}>
              <span>{t('price_5_11')}</span>
              <Price symbol={costs.currencySymbol} value={costsPayload.O_MINVB} />
            </li>
        </div>
      ),
    },
  ];

  const rankedItems = items.slice().sort((a, b) => a.rank - b.rank);


  return (
    <div className={styles.banner}>
      <div className={styles.banner_left}>
        <img className={styles.logo} alt='logo' src={`/assets/tiran-lab-logo.png`} />
        <h4>{t('header_1')}</h4>
        <Accordion
          allowMultipleExpanded={true}
          allowZeroExpanded={true}
          preExpanded={['Category-2']}
        >
          {rankedItems.map(item => (
            <AccordionItem
              key={item.uuid}
              uuid={item.uuid}
            >
              <AccordionItemHeading>
                <AccordionItemButton>{item.heading}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>{item.content}</AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>

        <h4>{t('header_2')}</h4>
        {/*        REMOVE SAME DAY SERVICE OPTION         */}
        {/*        <div className={styles.pricingLine}>
          <span>{t('extra_1')}</span>
          <Price symbol={costs.currencySymbol} value={costsPayload.sameDayService} sign />
        </div>
        */}
        {/*        REMOVE ATTEST OPTION         */}
        {/*<div className={styles.pricingLine}>
          <span>
            {t('extra_2')
              .split('\n')
              .map(text => (
                <span style={{ display: 'block' }} key={text}>
                  {text}
                </span>
              ))}
          </span>
          <Price symbol={costs.currencySymbol} value={costsPayload.englishResults} sign />
        </div>*/}
        <div className={styles.pricingLine}>
          <span>{t('extra_3')}</span>
          <Price symbol={costs.currencySymbol} value={costsPayload.invoice} sign />
        </div>
      </div>
      <div className={styles.banner_right}>
        {/*<img alt='banner' src={`/assets/Laboratory-bro-tiran-colour.svg`} />*/}
        <div className={styles.image_container}>
          <img src={`/assets/Labor-Dr-Tiran-scaled-37.jpg`} alt='Labor Tiran' className={styles.framed_image}/>
        </div>
      </div>
    </div>
  );
};
