import cx from 'classnames';
import React, { FC, useContext } from 'react';
import { store } from '../../store/store';
import { toCurrency } from '../../utils/string-manipulation';
import style from './Price.module.scss';

interface PriceProps {
  className?: string;
  minus?: boolean;
  sign?: boolean;
  symbol: string;
  value: number;
}

export const Price: FC<PriceProps> = ({ value, symbol, className, sign = false, minus = false }) => {
  const { state } = useContext(store);

  return (
    <span className={cx(className, style.price, minus && style.discount)}>
      {sign && '+'}
      {minus && '-'}
      {toCurrency(value, state.language)}
      {symbol}
    </span>
  );
};
