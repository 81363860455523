import React, { CSSProperties, FC, useEffect, useState } from 'react';
import colors from '../../styles/colors.scss';
import font from '../../styles/typography.scss';
import { parseNameFields } from '../../utils/string-manipulation';
import { Loader } from '../ui/Loader/Loader';

const { REACT_APP_CALENDLY_API_URL } = process.env;

interface Props {
  email?: string;
  name?: string;
  style: CSSProperties;
}

export const CalendlyWidget: FC<Props> = ({ style, email, name }) => {
  const primaryColor = colors.primaryColor.replace('#', '');
  const textColor = font.textColorDark.replace('#', '');
  const [isLoading, setLoading] = useState(!window.Calendly);
  const dataUrl = REACT_APP_CALENDLY_API_URL + `?text_color=${textColor}&primary_color=${primaryColor}`;
  const [patientFirstName, patientLastName] = parseNameFields(name);

  useEffect(() => {
    if (window.Calendly) {
      setLoading(false);
      window.Calendly.initInlineWidget({
        url: dataUrl,
        parentElement: document.querySelector('.calendly-inline-widget')!,
        prefill: { email, name: `${patientFirstName} ${patientLastName}`, first_name: patientFirstName, last_name: patientLastName },
      });
    } else {
      const head: HTMLHeadElement = document.querySelector('head')!;
      const script = document.createElement('script');
      const calendlyScriptId = 'calendly_external_widget_script';

      script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
      script.setAttribute('id', calendlyScriptId);
      script.onload = () => setLoading(false);
      head.appendChild(script);

      return () => {
        head.removeChild(document.getElementById(calendlyScriptId)!);
      };
    }
  // window.Calendly is a needed dependency otherwise it doesn't show - ignore warnings on compile
  }, [dataUrl, email, window.Calendly, patientFirstName, patientLastName]);

  if (isLoading) {
    return <Loader fullscreen size='large' />;
  }

  return <div className='calendly-inline-widget' data-auto-load={false} style={style} />;
};
