import React, { useEffect } from 'react';
import { Loader } from '../../components/ui/Loader/Loader';
import { useCosts } from '../../hooks/useCosts';
import { onBeforeUnloadListener } from '../../utils/events';
import { useShouldVerifyAccount } from '../HomePage/hooks/useShouldVerifyAccount';
import { BookingProductSelectionPage } from './BookingProductSelectionPage';

export const BookingProductSelectionPageContainer = () => {
  const { isLoadingCosts, costs } = useCosts();

  // ensures user has mail and phone number verified if registration is stopped at intermediate step
  useShouldVerifyAccount();

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnloadListener);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnloadListener);
    };
  }, []);

  if ( isLoadingCosts) {
    return <Loader fullscreen size='large' />;
  }

  return (
    <BookingProductSelectionPage
      patient={undefined}
      phoneNumber={''}
      preSelection={true}
      email={''}
      costs={costs!}
    />
  );
};
