import React, {FC} from 'react';
import {Button} from '../../components/ui/Button/Button';
import {useTranslation} from '../../hooks/useTranslation';
import {MainLocales} from '../../locales/types';
import { Cost } from '../../types/api-types';
import {Banner} from './Banner/Banner';
import style from './HomePage.module.scss';

interface Props {
    costs: Cost;
    handleBookingClick: () => void;
}

export const HomePage: FC<Props> = ({handleBookingClick, costs }) => {
    const {t} = useTranslation<MainLocales>('mainLocales');

    return (
        <div className={style.home}>
            <div className={style.left}>
                <h1>{t('homeTitle')}</h1>
                <p>{t('homeSubtitle')}</p>
                <div className={style.bookButtonContainer}>
                    <Button type='primary' onClick={handleBookingClick}>{t('homePageBookButton')}</Button>
                </div>
                <p className={style.secondaryLine}>{t('additionalLineOne')}</p>
                <p className={style.secondaryLine}>{t('additionalLineTwo')}</p>
            </div>
            <div className={style.right}>
                <Banner costs={costs} />
            </div>
        </div>
    );
};
