import React, { useContext } from 'react';
import { Loader } from '../../components/ui/Loader/Loader';
import { usePatient } from '../../hooks/usePatient';
import { store } from '../../store/store';
import { RequestFormDetailsPage } from './RequestFormDetailsPage';

export const RequestFormDetailsPageContainer = () => {
  const { state } = useContext(store);
  const { isLoadingPatient, patient } = usePatient();
  const { phone_number: phoneNumber, email } = state.currentUser?.attributes!;

  if (isLoadingPatient) {
    return <Loader fullscreen size='large' />;
  }

  return <RequestFormDetailsPage patient={patient?.patient} phoneNumber={phoneNumber!} email={email} />;
};
