import { AutoComplete as AntAutoComplete } from 'antd';
import { AutoCompleteProps as AntAutoCompleteProps } from 'antd/lib/auto-complete';
import cx from 'classnames';
import React, { FC, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Control, FieldError } from 'react-hook-form/dist/types/form';
import { handleInputFormat } from '../../../utils/input-handlers';
import { WithLabelAndError, WithLabelAndErrorProps } from '../hoc/WithLabelAndError';
import style from './AutoComplete.module.scss';

export interface AutoCompleteProps extends WithLabelAndErrorProps, Omit<AntAutoCompleteProps, 'onFocus'> {
  className?: string;
  control: Control;
  defaultValue?: string;
  error: FieldError | null;
  inputClassName?: string;
  label?: string;
  name: string;
  rules?: any;
}

export const AutoComplete: FC<AutoCompleteProps> =
  ({ required, label, error, className, inputClassName, name, control, defaultValue, options: initialOptions, disabled }) => {
    const [options, setOptions] = useState(initialOptions);
    const [searchText, setSearchText] = useState('');

    return (
      <WithLabelAndError error={error} required={required} label={label} className={className}>
        <Controller
          rules={{ required }}
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ value, onChange }) => (
            <AntAutoComplete
              size='large'
              className={cx(style.input, inputClassName)}
              onBlur={() => {
                if (searchText !== '' && options) {
                  onChange(options[0]?.value);
                }

                if (options?.length === 0) {
                  onChange('');
                  setOptions(initialOptions);
                }
              }}
              onSearch={search => {
                const newOptions = initialOptions?.filter(option => option.value.toLowerCase().indexOf(search.toLowerCase().trim()) !== -1);
                setOptions(newOptions);
                setSearchText(search);
              }}
              onSelect={selectedValue => {
                setSearchText('');
                onChange(selectedValue);
              }}
              onChange={onChange}
              value={value}
              options={options}
              // @ts-ignore
              onInput={handleInputFormat()}
              disabled={disabled}
            />
          )}
        />
      </WithLabelAndError>
    );
  };
