
import { useQuery } from 'react-query';
import { Cost } from '../types/api-types';
import { ApiPath } from '../types/enums/api-path.enum';
import { ApiQueries } from '../types/enums/api-queries.enum';

import { Fetch, FetchErrorResponse } from '../utils/fetch';

export const useCosts = () => {
  const { data, isLoading } = useQuery<Cost, ApiQueries, FetchErrorResponse>(ApiQueries.COSTS, async () => {
    const { data: responseData } = await Fetch.get<Cost>(
      ApiPath.COSTS,
    );

    return responseData;
  });

  return {
    costs: data,
    isLoadingCosts: isLoading,
  };
};
