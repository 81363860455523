import React, { FC } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import { MainLocales } from '../../../locales/types';
import { Button } from '../../ui/Button/Button';
import { Dialog } from '../../ui/Dialog/Dialog';
import style from './BookingLeavingWarningDialog.module.scss';

interface Props {
  handleCancel: () => void;
  handleConfirm: () => void;
  handleDialogClose: () => void;
  showDialog: boolean;
}

export const BookingLeavingWarningDialog: FC<Props> = ({ handleDialogClose, showDialog, handleCancel, handleConfirm }) => {
  const { t } = useTranslation<MainLocales>('mainLocales');

  return (
    <Dialog isOpen={showDialog} onRequestClose={handleDialogClose} className={style.modalContent}>
      <div>
        <p className={style.header}>{t('warningDialogHeader')}</p>
        <p className={style.content}>{t('warningDialogContent')}</p>
      </div>
      <div className={style.actionButtons}>
        <Button type='link' className={style.button} onClick={handleCancel}>{t('cancel')}</Button>
        <div className={style.divider}/>
        <Button type='link' className={style.button} onClick={handleConfirm} danger>{t('warningDialogYesQuit')}</Button>
      </div>
    </Dialog>
  );
};
