import { useContext } from 'react';
import { queryCache, useMutation } from 'react-query';
import { showErrorNotification } from '../../../components/ui/Notifications/Notifications';
import { store } from '../../../store/store';
import { Booking, PatientConfirmBookingDto } from '../../../types/api-types';
import { ApiPath } from '../../../types/enums/api-path.enum';
import { ApiQueries } from '../../../types/enums/api-queries.enum';
import { getUserToken } from '../../../utils/auth';
import { Fetch, FetchErrorResponse } from '../../../utils/fetch';
import { CalendlyRedirectParams } from '../interfaces/calendly-redirect-params.interface';

interface Args {
  patientData: PatientConfirmBookingDto;
  redirectParams: CalendlyRedirectParams;
}

export const useConfirmBooking = () => {
  const { state } = useContext(store);
  const [mutation, mutationOptions] = useMutation<Booking,
    Args,
    FetchErrorResponse>(async ({ patientData, redirectParams }: Args) => {
    const body: PatientConfirmBookingDto = {
      ...patientData,
      calendlyEventTypeUuid: redirectParams.event_type_uuid,
      calendlyInviteeUuid: redirectParams.invitee_uuid,
    };

    const { data } = await Fetch.post<Booking>(
      ApiPath.CONFIRM_BOOKING,
      body,
      { headers: { Authorization: await getUserToken(state) } },
    );

    return data;
  });

  if (!mutationOptions.isLoading && mutationOptions.error?.response) {
    showErrorNotification(mutationOptions.error.response.data, { defaultNotification: 'bookingConfirmationFailed' });
  }

  if (!mutationOptions.isLoading && mutationOptions.isSuccess) {
    queryCache.invalidateQueries(ApiQueries.PATIENT);
    queryCache.invalidateQueries(ApiQueries.DOCTORS);

    // IMPORTANT reset to avoid showing notifications on parent component rerender
    mutationOptions.reset();
  }

  return mutation;
};
