export const ONLINE_DISPUTE_RESOLUTION_URL = 'https://ec.europa.eu/odr';
export const COMPANY_NAME = 'Pandia Technology GmbH';
export const COMPANY_UID_NUMBER = 'AT U75831067';
export const COMPANY_REGISTRATION_NUMBER = 'FN 538561 b';
export const COMPANY_COMMERCIAL_COURT = 'ZRS Graz';
export const COMPANY_PROFESSIONAL_LAW_URL = 'https://www.ris.bka.gv.at';
export const COMPANY_PROFESSIONAL_LAW = 'Magistrat der Stadt Graz';
export const COMPANY_HEADQUARTERS = 'Quellengasse 55a\nA-8010 Graz';
export const COMPANY_MANAGER = 'Johannes Hönigl-Decrinis';
export const CONTACT_EMAIL = 'contact@cerdio.com';
