import { Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';
import cx from 'classnames';
import React, { FC } from 'react';
import style from './Loader.module.scss';

interface Props extends SpinProps {
  background?: boolean;
  fullscreen?: boolean;
}

export const Loader: FC<Props> = ({ fullscreen = false, background = false, ...props }) => {
  const Spinner = <Spin {...props} delay={200} />;
  const Content = background ? <div className={style.background}>{Spinner}</div> : Spinner;

  return <div className={cx(style.overlay, { [style.fullscreen]: fullscreen })}>{Content}</div>;
};
