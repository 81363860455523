import { init as SentryInit, Profiler, reactRouterV5Instrumentation } from '@sentry/react';
import { RouteConfig } from '@sentry/react/esm/reactrouter';
import { Integrations } from '@sentry/tracing';
import 'array-flat-polyfill';
import Amplify from 'aws-amplify';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { ReactQueryConfigProvider, ReactQueryProviderConfig } from 'react-query';
import { matchPath, Router } from 'react-router-dom';
import './bootstrap/i18n';
import { AppContainer } from './components/App/AppContainer';
import './index.less';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { StateProvider } from './store/store';
import { Environment } from './types/enums/environment.enum';
import { Path } from './types/enums/path.enum';
import { captureError } from './utils/sentry';

const {
  REACT_APP_ENV,
  REACT_APP_INSTANCE_NAME,
  REACT_APP_SENTRY_DSN,
  REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_AWS_COGNITO_REGION,
  REACT_APP_AWS_COGNITO_USER_POOL_ID,
  npm_package_version,
} = process.env;

const queryConfig: ReactQueryProviderConfig = {
  queries: {
    refetchOnWindowFocus: false,
    retry: false,
    onError: captureError,
  },
  mutations: { onError: captureError },
};

const history = createBrowserHistory();

const routes: RouteConfig[] = Object.values(Path).map(path => ({ path, exact: true }));

SentryInit({
  dsn: REACT_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: reactRouterV5Instrumentation(history, routes, matchPath),
    }),
  ],
  tracesSampleRate: 1.0,
  enabled: REACT_APP_ENV !== Environment.Local,
  environment: REACT_APP_INSTANCE_NAME,
  debug: REACT_APP_ENV === Environment.Development,
  release: 'booking-site@' + npm_package_version,
});

Amplify.configure({
  Auth: {
    region: REACT_APP_AWS_COGNITO_REGION,
    userPoolId: REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
  },
});

ReactDOM.render((
  <Profiler>
    <ReactQueryConfigProvider config={queryConfig}>
      <StateProvider>
        <Router history={history}>
          <AppContainer />
        </Router>
      </StateProvider>
    </ReactQueryConfigProvider>
  </Profiler>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
