import i18n from 'i18next';
import React, { ReactNode, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { setAppLanguage } from '../../../bootstrap/i18n';
import { APP_NAME } from '../../../constants/common';
import { useTranslation } from '../../../hooks/useTranslation';
import { useWindowWidth } from '../../../hooks/useWindowWidth';
import { LayoutLocales } from '../../../locales/types';
import { store } from '../../../store/store';
import breakpoints from '../../../styles/breakpoints.scss';
import { Language } from '../../../types/enums/language.enum';
import { Path } from '../../../types/enums/path.enum';
import { signOut } from '../../../utils/auth';
import { Dialog } from '../../ui/Dialog/Dialog';
import { Dropdown, DropdownDivider, DropdownMenu, DropdownMenuItem } from '../../ui/Dropdown/Dropdown';
import { Icon, IconType } from '../../ui/Icon/Icon';
import { showInfoNotification } from '../../ui/Notifications/Notifications';
import style from './NavMenu.module.scss';

// TODO use API configuration instead
const { REACT_APP_RESULTS_PAGE } = process.env;
const showResultsPage = REACT_APP_RESULTS_PAGE?.toString() === 'true';

interface MenuItem {
  content: string | ReactNode;
  icon: IconType;
  key: string;
  onClick: () => void;
}

export const NavMenu = () => {
  const { t } = useTranslation<LayoutLocales>('layoutLocales');
  const { push } = useHistory();
  const { dispatch, state } = useContext(store);
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth >= parseInt(breakpoints.tablet);
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const menuItems = ([
    {
      key: 'bookTest',
      icon: 'ic-book',
      onClick: () => push(Path.BOOKING),
      content: t('bookTest'),
    },
    showResultsPage
      ? {
          key: 'results',
          icon: 'ic-results',
          onClick: () => push(Path.RESULTS),
          content: t('checkResults'),
        }
      : null,
    {
      key: 'request form',
      icon: 'ic-form',
      onClick: () => push(Path.REQUEST_FORM_DETAILS),
      content: t('requestFormDetails'),
    },
    {
      key: 'settings',
      icon: 'ic-settings',
      onClick: () => push(Path.SETTINGS),
      content: t('settings'),
    },
    {
      key: 'language',
      icon: 'ic-locale',
      onClick: () => {switch(i18n.language) {
        case 'de':  setAppLanguage(Language.EN).then().catch(); state.language = Language.EN;
          break;
        default:  setAppLanguage(Language.DE).then().catch(); state.language = Language.DE;
          break;
        }},
      content: t('language'),
    },
  ] as MenuItem[]).filter(Boolean);

  const Menu = () => {
    return (
      <DropdownMenu className={style.navMenuContainer}>
        {menuItems.map(({ icon, onClick, content, key }) => (
          <DropdownMenuItem
            key={key}
            className={style.navMenuItem}
            icon={<Icon type={icon} alt={key} marginRight />}
            onClick={() => {
              onClick();
              setIsMenuVisible(false);
            }}>
            {content}
          </DropdownMenuItem>
        ))}
        <DropdownDivider key='divider' style={{ width: '100%' }} />
        <DropdownMenuItem
          key='logout'
          className={style.navMenuItem}
          style={{ marginTop: isDesktop ? undefined : '1rem' }}
          icon={<Icon type='ic-logout' alt='logout' marginRight />}
          onClick={() => {
            signOut({ dispatch, state });
            showInfoNotification('logout');
            setIsMenuVisible(false);
          }}>
          {t('logout')}
        </DropdownMenuItem>
      </DropdownMenu>
    );
  };

  const MobileMenu = () => {
    return (
      <Dialog isOpen={isMenuVisible} onRequestClose={() => setIsMenuVisible(false)} className={style.mobileMenuModalContent}>
        <div className={style.mobileMenuHeader}>
          <img alt='Cerdio logo' src='/assets/logo-white.svg' />
          <span className={style.appName}>{APP_NAME}</span>
        </div>
        <div className={style.mobileMenuContent}>
          <Menu />
        </div>
      </Dialog>
    );
  };

  return (
    <Dropdown
      overlay={isDesktop ? Menu : MobileMenu}
      trigger={isDesktop ? undefined : ['click']}
      onClick={() => setIsMenuVisible(true)}
      placement='bottomRight'
      arrow>
      <span className={style.navMenuTitle}>{state.currentUser!.attributes.email}</span>
      <span className={style.navMenuSubtitle}>{t('account')}</span>
    </Dropdown>
  );
};
