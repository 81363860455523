import React, { FC, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Box } from '../../components/ui/Box/Box';
import { Button } from '../../components/ui/Button/Button';
import { SUPPORT_EMAIL } from '../../constants/common';
import { useTranslation } from '../../hooks/useTranslation';
import { MainLocales } from '../../locales/types';
import { TRANSITION_TIMEOUT } from '../../styles/styles';
import { ChangeEmailFormContainer } from './ChangeEmailForm/ChangeEmailFormContainer';
import { ChangePasswordFormContainer } from './ChangePasswordForm/ChangePasswordFormContainer';
import style from './SettingsPage.module.scss';
import './SettingsPage.scss';

interface Props {
  email?: string;
}

interface EditingState {
  email?: boolean;
  password?: boolean;
}

export const SettingsPage: FC<Props> = ({ email }) => {
  const { t } = useTranslation<MainLocales>('mainLocales');
  const [editingState, setEditingState] = useState<EditingState>({ email: false, password: false });
  const handleChangeAttribute = (attr: keyof EditingState) => () => {
    setEditingState({ ...editingState, [attr]: !editingState[attr] });
  };

  return (
    <Box>
      <h1>{t('settingsHeader')}</h1>
      <div className={style.sectionContainer}>
        <p className={style.header}>{t('settingsEmailAddressHeader')}</p>
        <div className={style.flexbox}>
          <p className={style.subheader}>
            <span style={{ fontWeight: 'bold' }}>{email}</span>
          </p>
          <Button
            type='link'
            className={style.buttonLink}
            onClick={handleChangeAttribute('email')}
          >
            {t(editingState.email ? 'cancel' : 'change')}
          </Button>
        </div>
        <CSSTransition
          in={editingState.email}
          timeout={TRANSITION_TIMEOUT}
          classNames='collapsibleForm'
          unmountOnExit
        >
          <ChangeEmailFormContainer/>
        </CSSTransition>
      </div>
      <div className={style.sectionContainer}>
        <div className={style.flexbox}>
          <p className={style.header}>{t('password')}</p>
          <Button
            type='link'
            className={style.buttonLink}
            onClick={handleChangeAttribute('password')}
          >
            {t(editingState.password ? 'cancel' : 'change')}
          </Button>
        </div>
        <CSSTransition
          in={editingState.password}
          timeout={TRANSITION_TIMEOUT}
          classNames='collapsibleForm'
          unmountOnExit
        >
          <ChangePasswordFormContainer/>
        </CSSTransition>
      </div>
      <p className={style.bottomInfo}>
        {t('settingsSupportInfo')}
        <span style={{ fontWeight: 600 }}><a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a></span>
        <span>.</span>
      </p>
    </Box>
  );
};
