import { ConfigProvider } from 'antd';
import deDE from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import React, { useContext, useEffect, useState } from 'react';
import { setAppLanguage } from '../../bootstrap/i18n';
import { RECAPTCHA_TIMEOUT } from '../../constants/common';
import { store } from '../../store/store';
import { Language } from '../../types/enums/language.enum';
import { Error500 } from '../Errors/Error500';
import { Loader } from '../ui/Loader/Loader';
import { App } from './App';

export const AppContainer = () => {
  const [recaptchaReady, setRecaptchaReady] = useState(false);
  const [recaptchaTimeout, setRecaptchaTimeout] = useState(false);
  const { state } = useContext(store);
  const [selectedLanguage,setSelectedLanguage] = useStickyState(state.language,'selectedLanguage');

  function useStickyState(defaultValue:Language, key:string) {
    const [value, setValue] = React.useState(() => {
      const stickyValue = window.localStorage.getItem(key);

      return stickyValue !== null
        ? JSON.parse(stickyValue)
        : defaultValue;
    });
    React.useEffect(() => {
      window.localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
  }

  useEffect(() => {
    // tslint:disable-next-line:no-floating-promises
    setAppLanguage(selectedLanguage);
    state.language = selectedLanguage;
    if(selectedLanguage !== state.language){setSelectedLanguage(state.language);}
  }, [state.language, selectedLanguage, setSelectedLanguage]);

  const timeoutId = setTimeout(() => setRecaptchaTimeout(true), RECAPTCHA_TIMEOUT);
  grecaptcha.ready(() => {
    clearTimeout(timeoutId);
    setRecaptchaReady(true);
  });

  if (recaptchaTimeout) {
    return <Error500 />;
  }

  if (!recaptchaReady || !state.currentUserReady) {
    return <Loader fullscreen size='large' />;
  }

  return (
    <ConfigProvider locale={state.language === Language.EN ? enUS : deDE}>
      <App />
    </ConfigProvider>
  );
};
