import { joiResolver } from '@hookform/resolvers';
import React, { FC, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BookingForm } from '../../components/BookingForm/BookingForm';
import { BookingLeavingGuard } from '../../components/BookingLeavingGuard/BookingLeavingGuard';
import { BookingOptionalExtras } from '../../components/BookingOptionalExtras/BookingOptionalExtras';
import { Box } from '../../components/ui/Box/Box';
import { Icon } from '../../components/ui/Icon/Icon';
import { store } from '../../store/store';
import { Cost, Patient } from '../../types/api-types';
import { Path } from '../../types/enums/path.enum';
import { BookingStep } from './enums/booking-step.enum';
import { bookingCalendlyRedirectInputsSchema, BookingCalendlyRedirectInputsSchema, RedirectParams } from './validation';

interface Props {
  costs: Cost;
  email: string;
  onDataSubmit: (data: any) => Promise<void>;
  patient?: Patient;
  phoneNumber: string;
  redirectParams?: RedirectParams;
}

export const BookingCalendlyRedirectPage: FC<Props> = ({ onDataSubmit, ...props }) => {
  const { state } = useContext(store);
  const [step, setBookingStep] = useState(BookingStep.BOOKING_FORM);
  const methods = useForm<BookingCalendlyRedirectInputsSchema>({
    resolver: joiResolver(bookingCalendlyRedirectInputsSchema),
    mode: 'onChange',
  });

  const handleBackIconClick = async () => {
    const isValid = await methods.trigger();
    // tslint:disable-next-line:no-console
    // console.log('handleBackIconClick from BookingCalendlyRedirectPage.tsx');
    if (isValid && setBookingStep) {
      setBookingStep(BookingStep.BOOKING_FORM);
    }
  };


  return (
    <>
      <Box>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onDataSubmit)}>
            {step === BookingStep.OPTIONAL_EXTRAS ? <Icon alt='<-' type='ic-back' onClick={handleBackIconClick} marginBottom /> : <p/>}
            <BookingForm {...props} setBookingStep={setBookingStep} visible={step === BookingStep.BOOKING_FORM} />
            <BookingOptionalExtras {...props} setBookingStep={setBookingStep} visible={step === BookingStep.OPTIONAL_EXTRAS} />
          </form>
        </FormProvider>
      </Box>
      <BookingLeavingGuard
        when={state.bookingLeavingGuardEnabled}
        // This case it blocks the navigation when user is going to different page that calendly redirect page or /payments
        shouldBlockNavigation={newLocation => ![Path.BOOKING_CALENDLY_REDIRECT].includes(newLocation.pathname as Path)}
      />
    </>
  );
};
