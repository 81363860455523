export enum HomePageDialogState {
  // AUTH FLOW
  loginEmailBookingFlow = 'loginEmailBookingFlow',
  loginEmail = 'loginEmail',
  loginPassword = 'loginPassword',
  registerPassword = 'registerPassword',
  phoneConfirmation = 'phoneConfirmation',
  otpConfirmation = 'otpConfirmation',
  confirmationEmailSent = 'confirmationEmailSent',
  forgotPassword = 'forgotPassword',

  // USER SETTINGS
  resetPasswordEmailSent = 'resetPasswordEmailSent',
  resetPassword = 'resetPassword',
  resetPasswordSuccess = 'resetPasswordSuccess',
  newEmailConfirmationSuccess = 'newEmailConfirmationSuccess',

  // BOOKING PAYMENT
  paymentSuccess = 'paymentSuccess',
  paymentFailure = 'paymentFailure',
}
