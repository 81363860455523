import cx from 'classnames';
import moment from 'moment';
import React, { FC, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { MAX_POSTAL_CODE_LENGTH, MAX_SVNUMMER_LENGTH, SUPPORT_EMAIL } from '../../constants/common';
import { useTranslation } from '../../hooks/useTranslation';
import { getCountryNames } from '../../locales/countries';
import { MainLocales } from '../../locales/types';
import { BookingStep } from '../../routes/BookingCalendlyRedirectPage/enums/booking-step.enum';
import { BookingCalendlyRedirectInputsSchema, RedirectParams } from '../../routes/BookingCalendlyRedirectPage/validation';
import { store } from '../../store/store';
import { Patient } from '../../types/api-types';
import { DocumentType } from '../../types/enums/document-type.enum';
import { Gender } from '../../types/enums/gender.enum';
import { SendResultsTo } from '../../types/enums/send-results-to.enum';
import { parseAddressFields, parseNameFields } from '../../utils/string-manipulation';
import { AutoComplete } from '../ui/AutoComplete/AutoComplete';
import { Button } from '../ui/Button/Button';
import { DatePicker } from '../ui/DatePicker/DatePicker';
import { Input } from '../ui/Input/Input';
import { Radio, RadioGroup } from '../ui/RadioGroup/RadioGroup';
import style from './BookingForm.module.scss';

// TODO use API configuration instead
const { REACT_APP_RESULTS_PAGE } = process.env;
const showResultsPage = REACT_APP_RESULTS_PAGE?.toString() === 'true';

interface Props {
  email: string;
  patient?: Patient;
  phoneNumber: string;
  readonly?: boolean;
  redirectParams?: RedirectParams;
  setBookingStep?: (step: BookingStep) => void;
  visible?: boolean;
}

export const BookingForm: FC<Props> = props => {
  const { redirectParams: params, patient, readonly, phoneNumber, email, setBookingStep } = props;
  const { control, errors, formState, setValue, trigger, clearErrors } = useFormContext<BookingCalendlyRedirectInputsSchema>();
  const { state } = useContext(store);
  const { t } = useTranslation<MainLocales>('mainLocales');
  const [patientFirstName, patientLastName] = parseNameFields(patient?.name || '');
  const [patientStreetName, patientHouseNumber] = parseAddressFields(patient?.address || '');
  const firstName = params?.invitee_first_name || patientFirstName;
  const lastName = params?.invitee_last_name || patientLastName;

  const PageSubheader = () => {
    return readonly ? (
      <p className={style.fullwidthColumn} style={{ marginRight: 0 }}>
        <span style={{ display: 'block' }}>{t('bookingFormSubheaderReadonly1')}</span>
        <span>{t('bookingFormSubheaderReadonly2')}</span>
        <span style={{ fontWeight: 600 }}>
          <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
        </span>
        <span>.</span>
      </p>
    ) : (
      <>
        <p className={style.column} style={{ marginRight: 0 }}>
          {t('bookingFormSubheader')}
        </p>
        <div className={cx(style.column, style.mobileOnly)} />
      </>
    );
  };

  const handleNextButton = async () => {
    const isValid = await trigger();
    if (isValid && setBookingStep) {
      // This must be in sessionStorage to persist past a Calendly redirect or a page refresh
      // tslint:disable-next-line:no-console
      // console.log('handleNextButton from BookingForm.tsx');
      window.sessionStorage.removeItem('invPreSel');
      setBookingStep(BookingStep.OPTIONAL_EXTRAS);
    }
  };

  return (
    <div style={{ width: '100%', display: props.visible ? 'block' : 'none' }}>
      <h1>{t('bookingFormHeader')}</h1>
      <div className={style.row}>
        <PageSubheader />
      </div>

      <p className={style.header}>
        {t('bookingFormPersonalHeader')
          .split('\n')
          .map((item, key) => (
            <span key={key}>
              {item}
              <br />
            </span>
          ))}
      </p>
      <div className={style.row}>
        <div className={style.column}>
          <Input
            control={control}
            name='firstName'
            defaultValue={firstName}
            label={t('firstName')}
            className={style.field}
            error={errors.firstName!}
            disabled={readonly}
            required
          />
        </div>
        <div className={style.column}>
          <Input
            control={control}
            name='lastName'
            defaultValue={lastName}
            label={t('lastName')}
            className={style.field}
            error={errors.lastName!}
            disabled={readonly}
            required
          />
        </div>
      </div>
      <div className={style.row}>
        <div className={style.column}>
          <DatePicker
            control={control}
            name='birthDate'
            format={'DD.MM.YYYY'}
            label={t('birthDate')}
            inputClassName={style.field}
            error={errors.birthDate!}
            defaultValue={patient ? moment(patient?.birthDate) : undefined}
            disabled={readonly}
            required
          />
        </div>
        <div className={style.column}>
          <Input
            control={control}
            name={'documents.' + DocumentType.NRICFIN}
            label={t(DocumentType.NRICFIN)}
            className={style.field}
            error={errors.documents?.NRICFIN!}
            defaultValue={patient?.documents?.NRICFIN || undefined }
            maxLength={MAX_SVNUMMER_LENGTH}
            disabled={readonly}
          />
        </div>
        <div className={style.column}>
          <RadioGroup
            name='gender'
            control={control}
            label={t('gender')}
            className={style.field}
            error={errors.gender!}
            onChange={({ target }) => {
              setValue('gender', target.value);
              clearErrors('gender');
            }}
            defaultValue={patient?.gender}
            disabled={readonly}
            required>
            <Radio value={Gender.MALE} layout='horizontal'>
              {t(Gender.MALE)}
            </Radio>
            <Radio value={Gender.FEMALE} layout='horizontal'>
              {t(Gender.FEMALE)}
            </Radio>
          </RadioGroup>
        </div>
      </div>

      <p className={style.header}>{t('bookingFormContactHeader')}</p>
      <div className={style.row}>
        <div className={style.column}>
          {/*Must be Input and not PhoneNumberInput otherwise react-hook-form fails*/}
          <Input
            name='phoneNumber'
            error={null}
            control={control}
            className={style.field}
            defaultValue={phoneNumber}
            label={t('phoneNumber')}
            required
            disabled
          />
        </div>
        <div className={style.column}>
          <Input
            control={control}
            name='email'
            label={t('email')}
            defaultValue={email}
            className={style.field}
            error={errors.email!}
            required
            disabled
          />
        </div>
        {showResultsPage && (
          <div className={style.column}>
            <RadioGroup
              name='sendResultsTo'
              control={control}
              label={t('sendResultsTo')}
              className={style.field}
              error={errors.sendResultsTo!}
              onChange={({ target }) => {
                setValue('sendResultsTo', target.value);
                clearErrors('sendResultsTo');
              }}
              defaultValue={SendResultsTo.EMAIL}
              disabled={readonly}
              required>
              <Radio value={SendResultsTo.EMAIL}>{t('email')}</Radio>
              <Radio value={SendResultsTo.MOBILE}>{t('phoneNumber')}</Radio>
            </RadioGroup>
          </div>
        )}
      </div>

      <p className={style.header}>{t('bookingFormAddressHeader')}</p>
      <div className={style.row}>
        <div className={style.wideColumn}>
          <Input
            control={control}
            name='streetName'
            label={t('streetName')}
            className={style.field}
            error={errors.streetName!}
            defaultValue={patientStreetName}
            disabled={readonly}
          />
        </div>
        <div className={style.column}>
          <Input
            control={control}
            name='houseNumber'
            label={t('houseNumber')}
            className={style.field}
            error={errors.houseNumber!}
            defaultValue={patientHouseNumber}
            disabled={readonly}
          />
        </div>
      </div>
      <div className={style.row}>
        <div className={style.column}>
          <Input
            control={control}
            name='postalCode'
            label={t('postalCode')}
            className={style.field}
            error={errors.postalCode!}
            defaultValue={patient?.postalCode}
            maxLength={MAX_POSTAL_CODE_LENGTH}
            disabled={readonly}
          />
        </div>
        <div className={style.column}>
          <Input
            control={control}
            name='city'
            label={t('city')}
            className={style.field}
            error={errors.city!}
            defaultValue={patient?.city}
            disabled={readonly}
          />
        </div>
        <div className={style.column}>
          <AutoComplete
            control={control}
            name='country'
            label={t('country')}
            className={style.field}
            error={errors.country!}
            defaultValue={patient?.country}
            options={getCountryNames(state.language).map(name => ({ value: name, label: name }))}
            disabled={readonly}
          />
        </div>
      </div>

      {!readonly && (
        <Button type='primary' disabled={formState.isSubmitting || !formState.isValid} style={{ marginTop: '3rem' }} onClick={handleNextButton}>
          {t('next')}
        </Button>
      )}
    </div>
  );
};
