import { CaretDownOutlined } from '@ant-design/icons/lib';
import { Dropdown as AntdDropdown, Menu as AntdMenu } from 'antd';
import { MenuItemProps as AntdMenuItemProps } from 'antd/es/menu/MenuItem';
import { DropDownProps as AntdDropdownProps } from 'antd/lib/dropdown';
import { MenuProps as AntdMenuProps } from 'antd/lib/menu';
import cx from 'classnames';
import React, { FC } from 'react';
import { Button } from '../Button/Button';
import style from './Dropdown.module.scss';

export interface DropdownProps extends Omit<AntdDropdownProps, 'loading' | 'style'> {
  onClick?: () => void;
}

export const Dropdown: FC<DropdownProps> = ({ className, children, onClick, ...props }) => {
  return (
    <AntdDropdown className={cx(style.dropdown, className)} {...props}>
      <Button type='primary' secondary className={style.dropdownButton} onClick={onClick}>
        <div className={style.dropdownInnerContainer}>
          <p className={style.dropdownButtonText}>
            {children}
          </p>
          <CaretDownOutlined/>
        </div>
      </Button>
    </AntdDropdown>
  );
};

export const DropdownMenu: FC<AntdMenuProps> = ({ className, ...props }) => {
  return (
    <AntdMenu className={cx(style.menu, className)} {...props} />
  );
};

export const DropdownMenuItem: FC<AntdMenuItemProps> = ({ className, ...props }) => {
  return (
    <AntdMenu.Item className={cx(style.menuItem, className)} {...props} />
  );
};

export const DropdownDivider = AntdMenu.Divider;
