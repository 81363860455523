import React, { FC, useContext, useState } from 'react';
import { Dialog } from '../../../components/ui/Dialog/Dialog';
import { APP_NAME } from '../../../constants/common';
import { store } from '../../../store/store';
import { HomePageDialogState } from '../../../types/enums/home-page-dialog-state.enum';
import { BookingFailure } from './DialogContent/BookingResults/BookingFailure';
import { BookingSuccess } from './DialogContent/BookingResults/BookingSuccess';
import { ConfirmationEmailSentContainer } from './DialogContent/ConfirmationEmailSent/ConfirmationEmailSentContainer';
import { ForgotPasswordContainer } from './DialogContent/ForgotPassword/ForgotPasswordContainer';
import { LoginEmailContainer } from './DialogContent/LoginEmail/LoginEmailContainer';
import { LoginPasswordContainer } from './DialogContent/LoginPassword/LoginPasswordContainer';
import { NewEmailConfirmationSuccess } from './DialogContent/NewEmailConfirmationSuccess/NewEmailConfirmationSuccess';
import { OtpConfirmationContainer } from './DialogContent/OtpConfirmation/OtpConfirmationContainer';
import { PasswordResetContainer } from './DialogContent/PasswordReset/PasswordResetContainer';
import { PhoneConfirmationContainer } from './DialogContent/PhoneConfirmation/PhoneConfirmationContainer';
import { RegisterPasswordContainer } from './DialogContent/RegisterPassword/RegisterPasswordContainer';
import { ResetPasswordEmailSentContainer } from './DialogContent/ResetPasswordEmailSent/ResetPasswordEmailSentContainer';
import { ResetPasswordSuccess } from './DialogContent/ResetPasswordSuccess/ResetPasswordSuccess';
import style from './HomePageDialog.module.scss';

interface HomePageDialogProps {
  handleDialogClose: () => void;
  showDialog: boolean;
}

export const HomePageDialog: FC<HomePageDialogProps> = ({ handleDialogClose, showDialog }) => {
  const { state } = useContext(store);
  const [loginEmail, setLoginEmail] = useState<string>(state.currentUser?.attributes.email ?? '');
  const [phoneNumber, setPhoneNumber] = useState<string>(state.currentUser?.attributes?.phone_number ?? '');
  const [isBookingFlow, setIsBookingFlow] = useState<boolean>(false);

  const DialogContent = () => {
    switch (state.homePageDialogState) {
      case HomePageDialogState.loginEmail: {
        setIsBookingFlow(false);

        return <LoginEmailContainer setEmail={setLoginEmail} isBookingFlow={isBookingFlow} defaultEmail={loginEmail} />;
      }
      case HomePageDialogState.loginEmailBookingFlow: {
        setIsBookingFlow(true);

        return <LoginEmailContainer setEmail={setLoginEmail} isBookingFlow={isBookingFlow} defaultEmail={loginEmail} />;
      }
      case HomePageDialogState.loginPassword: {
        return <LoginPasswordContainer isBookingFlow={isBookingFlow} email={loginEmail} />;
      }
      case HomePageDialogState.registerPassword: {
        return <RegisterPasswordContainer isBookingFlow={isBookingFlow} email={loginEmail} />;
      }
      case HomePageDialogState.confirmationEmailSent: {
        return <ConfirmationEmailSentContainer email={loginEmail} />;
      }
      case HomePageDialogState.phoneConfirmation: {
        return <PhoneConfirmationContainer defaultPhoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />;
      }
      case HomePageDialogState.otpConfirmation: {
        return <OtpConfirmationContainer phoneNumber={phoneNumber} />;
      }
      case HomePageDialogState.forgotPassword: {
        return <ForgotPasswordContainer setEmail={setLoginEmail} email={loginEmail} />;
      }
      case HomePageDialogState.resetPasswordEmailSent: {
        return <ResetPasswordEmailSentContainer email={loginEmail} />;
      }
      case HomePageDialogState.resetPassword: {
        return <PasswordResetContainer />;
      }
      case HomePageDialogState.resetPasswordSuccess: {
        return <ResetPasswordSuccess />;
      }
      case HomePageDialogState.newEmailConfirmationSuccess: {
        return <NewEmailConfirmationSuccess />;
      }
      case HomePageDialogState.paymentSuccess: {
        window.sessionStorage.clear();

        return <BookingSuccess />;
      }
      case HomePageDialogState.paymentFailure: {
        return <BookingFailure />;
      }
    }

    return null;
  };

  return (
    <Dialog isOpen={showDialog} onRequestClose={handleDialogClose} className={style.modalContent}>
      <div className={style.header}>
        <img alt={`${APP_NAME} logo`} src='/assets/logo-white.svg' />
        <span className={style.appName}>{APP_NAME}</span>
      </div>
      <div className={style.content}>
        <DialogContent />
      </div>
    </Dialog>
  );
};
