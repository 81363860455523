export enum Path {
  BOOKING = '/booking',
  BOOKING_CALENDLY_REDIRECT = '/booking-redirect',
  COMPANY = '/company',
  EMAIL_CONFIRMATION = '/auth/confirm-email',
  HOME = '/',
  NEW_EMAIL_CONFIRMATION = '/auth/confirm-new-email',
  PASSWORD_RESET = '/auth/reset-password',
  PAYMENT = '/payment',
  PRIVACY_POLICY = '/privacy-policy',
  PRODUCT_SELECTION = '/product-selection',
  REQUEST_FORM_DETAILS = '/request-form-details',
  RESULTS = '/results',
  SETTINGS = '/settings',
  TERMS = '/terms',
}
