import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { ErrorResponse } from '../types/interfaces/error-response.interface';
import { captureError } from './sentry';

export declare type FetchErrorResponse = AxiosError<ErrorResponse>;

const { REACT_APP_API_URL, REACT_APP_API_TOKEN } = process.env;
const fetchConfig: AxiosRequestConfig = {
  baseURL: REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-token': REACT_APP_API_TOKEN,
  },
};

export const Fetch: AxiosInstance = axios.create(fetchConfig);

Fetch.interceptors.response.use(
  value => value,
  async error => {
    await captureError(error);
    throw error;
  },
);
