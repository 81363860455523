import cx from 'classnames';
import React, { CSSProperties, FC, useRef } from 'react';
import { FieldError } from 'react-hook-form/dist/types/form';
import { useTranslation } from '../../../hooks/useTranslation';
import { Validation } from '../../../locales/types';
import style from './WithLabelAndError.module.scss';

export interface WithLabelAndErrorProps {
  className?: string;
  error: FieldError | null;
  label?: string;
  required?: boolean;
  style?: CSSProperties;
}

export const WithLabelAndError: FC<WithLabelAndErrorProps> = ({ required, label, error, children, className }) => {
  const { t } = useTranslation<Validation>('validation');
  const ref = useRef<HTMLDivElement>(null);
  if (ref && error) {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div className={cx(style.container, className)}>
      {label && <p className={style.label}>{label}{required && ' *'}</p>}
      {children}
      {error && <p className={style.error} role='alert'>{error && t(error.type as keyof Validation)}</p>}
    </div>
  );
};
