import React, { FC, useContext } from 'react';
import { Button } from '../../../../../components/ui/Button/Button';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { MainLocales } from '../../../../../locales/types';
import { store } from '../../../../../store/store';
import { hideHomePageDialog } from '../../../../../utils/home-page-dialog-state-handlers';
import dialogsStyle from '../Dialogs.module.scss';

export const ResetPasswordSuccess: FC = () => {
  const { dispatch } = useContext(store);
  const { t } = useTranslation<MainLocales>('mainLocales');

  const handleDoneClick = () => hideHomePageDialog(dispatch);

  return (
    <div className={dialogsStyle.content}>
      <div className={dialogsStyle.left}>
        <h1>{t('resetPasswordSuccessHeader')}</h1>
        <p className={dialogsStyle.info}>{t('resetPasswordSuccessInfo')}</p>
        <div>
          <Button type='primary' onClick={handleDoneClick}>
            {t('successDone')}
          </Button>
        </div>
      </div>
    </div>
  );
};
