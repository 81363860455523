import { Table as AntdTable } from 'antd';
import { TableProps as AntdTableProps } from 'antd/lib/table';
import React, { FC } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import { MainLocales } from '../../../locales/types';
import './Table.scss';

export interface TableProps extends AntdTableProps<any> {}

export const Table: FC<TableProps> = props => {
  const { t } = useTranslation<MainLocales>('mainLocales');

  return (
    <AntdTable
      pagination={false}
      size='small'
      locale={{ emptyText: t('noData') }}
      {...props}
    />
  );
};
