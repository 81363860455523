import React, { CSSProperties, FC } from 'react';
import { BookingLeavingGuard } from '../../components/BookingLeavingGuard/BookingLeavingGuard';
import { CalendlyWidget } from '../../components/CalendlyWidget/CalendlyWidget';
import { Path } from '../../types/enums/path.enum';

interface BookingPageProps {
  email?: string;
  isCalendlyFormDirty: boolean;
  name?: string;
  widgetStyle: CSSProperties;
}

export const BookingPage: FC<BookingPageProps> = ({ email, isCalendlyFormDirty, name, widgetStyle }) => {
  return (
    <>
      <CalendlyWidget email={email} style={widgetStyle} name={name}/>
      <BookingLeavingGuard
        when={isCalendlyFormDirty}
        // This case it blocks the navigation when user is going to different page that /booking or calendly redirect page
        // TODO test if this changes behaviour compared to Path.BOOKING
        shouldBlockNavigation={newLocation => newLocation.pathname !== Path.BOOKING_CALENDLY_REDIRECT}
      />
    </>
  );
};
