import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import { Routes } from '../../routes/Routes';
import { Environment } from '../../types/enums/environment.enum';
import { Error500 } from '../Errors/Error500';
import { Layout } from '../Layout/Layout';

const { REACT_APP_ENV } = process.env;
const isDevelopment = REACT_APP_ENV !== Environment.Production; // TODO precise

export const App = () => {
  return (
    <Layout>
      <ErrorBoundary fallback={<Error500 />} showDialog={isDevelopment}>
        <Routes />
      </ErrorBoundary>
    </Layout>
  );
};
