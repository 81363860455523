import React from 'react';
import { Box } from '../../components/ui/Box/Box';
import { CONTACT_EMAIL } from '../../constants/company';
import { useTranslation } from '../../hooks/useTranslation';
import { PrivacyPolicyPageLocales } from '../../locales/types';
import style from './PrivacyPolicyPage.module.scss';

export const PrivacyPolicyPage = () => {
  const { t } = useTranslation<PrivacyPolicyPageLocales>('privacyPolicyPageLocales');

  const BunnyPrivacyLink = () => (
    <a href='https://bunny.net/privacy/' target='_blank' rel='noopener noreferrer'>
      https://bunny.net/privacy/
    </a>
  );

  const GooglePrivacyLink = () => (
    <a href='https://www.google.com/policies/privacy' target='_blank' rel='noopener noreferrer'>
      https://www.google.com/policies/privacy
    </a>
  );

  return (
    <Box className={style.container}>
      <h2>{t('header')}</h2>

      <h3 className={style.header}>{t('header1')}</h3>
      <p>{t('header1part1')}</p>
      <p>{t('header1part2')}</p>

      <h3 className={style.header}>{t('header2')}</h3>
      <p>{t('header2part1')}</p>

      <h3 className={style.header}>{t('header3')}</h3>
      <p>{t('header3part1')}</p>
      <span>{t('header3part2')}</span>
      <ul>
        <li>{t('header3part2a')}</li>
        <li>{t('header3part2b')}</li>
      </ul>
      <p>{t('header3part3')}</p>
      <p>{t('header3part4')}</p>
      <p>{t('header3part5')}</p>
      <p>
        <span>{t('header3part6')}</span>
        <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
      </p>
      <p>{t('header3part7')}</p>
      <p>{t('header3part8')}</p>

      <h3 className={style.header}>{t('header4')}</h3>
      <p>{t('header4part1')}</p>
      <p>{t('header4part2')}</p>
      <p>{t('header4part3')}</p>
      <p>{t('header4part4')}</p>

      <h3 className={style.header}>{t('header5')}</h3>
      <p>{t('header5part1')}</p>
      <p>{t('header5part2')}</p>
      <p>
        <span>{t('header5part3')}</span>
        <a href='https://fonts.bunny.net/faq' target='_blank' rel='noopener noreferrer'>
          https://fonts.bunny.net/faq
        </a>
        <span>{t('header5part4')}</span>
        <BunnyPrivacyLink />
      </p>

      <h3 className={style.header}>{t('header6')}</h3>
      <p>
        <span>{t('header6part1')}</span>
        <span>{t('header6part2')}</span>
        <GooglePrivacyLink />
      </p>

      <h3 className={style.header}>{t('header7')}</h3>
      <p>{t('header7part1')}</p>
      <p>{t('header7part2')}</p>
      <p>
        <span>{t('header7part3')}</span>
        <a href='https://calendly.com/de/pages/security' target='_blank' rel='noopener noreferrer'>
          https://calendly.com/de/pages/security
        </a>
        <span>{t('header7part4')}</span>
        <a href='https://calendly.com/de/pages/privacy' target='_blank' rel='noopener noreferrer'>
          https://calendly.com/de/pages/privacy
        </a>
      </p>

      <h3 className={style.header}>{t('header8')}</h3>
      <p>
        <span>{t('header8part1')}</span>
        <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
        <span>{t('header8part2')}</span>
      </p>
    </Box>
  );
};
