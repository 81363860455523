import { withSentryRouting } from '@sentry/react';
import React, { FC, JSXElementConstructor, useContext, useEffect, useState } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Loader } from '../../components/ui/Loader/Loader';
import { store } from '../../store/store';
import { Path } from '../../types/enums/path.enum';
import { UserVerificationState } from '../../types/enums/user-verification-state.enum';
import { getUserVerificationState, isUserSessionValid } from '../../utils/auth';

interface Props extends RouteProps {
  component: JSXElementConstructor<any>;
}

export const PrivateRoute: FC<Props> = withSentryRouting(({ component: Component, ...rest }) => {
  const [isUserLogged, setLogged] = useState<boolean | undefined>(undefined);
  const { state } = useContext(store);
  const isUserVerified = getUserVerificationState(state.currentUser!) === UserVerificationState.VERIFIED;
  const getBaseComponent = (props: any) => <Component {...props} />;
  const getRedirectComponent = (props: any) => <Redirect to={{ pathname: Path.HOME, state: { from: props.location } }} />;

  useEffect(() => {
    isUserSessionValid()
      .then(isValid => setLogged(isValid))
      .catch(() => setLogged(false));
  }, [setLogged]);

  if (isUserLogged === undefined) {
    return <Loader fullscreen size='large' />;
  }

  return <Route {...rest} render={isUserLogged && isUserVerified ? getBaseComponent : getRedirectComponent} />;
});
